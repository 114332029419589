import React from "react";
import AppContent from "../components/AppContent";
import Header from "../views/menus/Header";
import Footer from "../views/menus/Footer";
import FooterAcceuil from "../views/menus/FooterAcceuil";


const DefaultLayout = () => {
    return (

        <>
            <Header />
            <AppContent />
            <Footer />
        </>
    )
}

export default DefaultLayout