import React, { useState } from "react";
import { DASH_LOGIN, LOGO_FAYSAL_COMPANY } from "../../components/icons";
import { Link, useNavigate } from "react-router-dom";
import api from '../../config/index'
import { Alert, Space } from "antd";

const LogUp = () => {

    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEamil] = useState("");

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");

    const loginCreateAccount = async (event: any) => {
        event.preventDefault();
        let data = JSON.stringify({
            "fullname": username,
            "phone": phone,
            "email": email
        });
        setLoading(true);

        if (username === '' || phone === '') {
            setShowAlertDanger(true)
            setMessage('Veillez completer tout les champs');
        } else {
            api.getHttpRequestNotToken(`/user/create`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status === 200) {

                    navigate('/home')
                }
                else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                setShowAlertDanger(true)
                setMessage(api.getMessageError(error))
                setPhone('')
                setEamil('')
                setUsername('')
            })
        }
        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
        }, 10000);
    }
    return (
        <>
            <section className="sign-in-page">
                <div id="container-inside">
                    <div style={{ borderColor: '#E32A25' }} className="cube"></div>
                    <div style={{ borderColor: '#E32A25' }} className="cube"></div>
                    <div style={{ borderColor: '#E32A25' }} className="cube"></div>
                    <div style={{ borderColor: '#E32A25' }} className="cube"></div>
                    <div style={{ borderColor: '#E32A25' }} className="cube"></div>
                </div>
                <div className="container p-0">
                    <div className="row no-gutters height-self-center">
                        <div className="col-sm-12 align-self-center bg-primary rounded">
                            <div className="row m-0">
                                <div style={{ backgroundColor: 'white' }} className="col-md-5 sign-in-page-data">
                                    <div className="sign-in-from">
                                        <h1 className="mb-0 text-center">Creer un compte</h1>
                                        <p className="text-center text-dark">Enter your email address and password to access admin panel.</p>
                                        <form className="mt-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Nom Complet</label>
                                                <input type="email" className="form-control mb-0" id="exampleInputEmail1" placeholder="Nom complet"
                                                 value={username}
                                                 onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Password</label>
                                                <input type="password" className="form-control mb-0" id="exampleInputPassword1" placeholder="Mot de passe" 
                                                  value={phone}
                                                  onChange={(e)=> setPhone(e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail2">Email address</label>
                                                <input type="email" className="form-control mb-0" id="exampleInputEmail2" placeholder="Email adress" 
                                                  value={email}
                                                  onChange={(e) => setEamil(e.target.value)}
                                                />
                                            </div>
                                            
                                            <div className="d-inline-block w-100">
                                                <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                    <label className="custom-control-label" htmlFor="customCheck1">I accept <a href="#">Terms and Conditions</a></label>
                                                </div>
                                            </div>
                                            <div className="sign-info text-center">
                                                <button style={{ backgroundColor: '#E32A25', borderColor: '#E32A25' }} type="submit" className="btn btn-primary d-block w-100 mb-2">Sign Up</button>
                                                <span className="text-dark d-inline-block line-height-2">Already Have Account ? <Link to="/login">Log In</Link></span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#E32A25' }} className="col-md-7 text-center sign-in-page-image">
                                    <div className="sign-in-detail text-white">
                                        <a className="sign-in-logo mb-5" href="#"><img src={LOGO_FAYSAL_COMPANY()} className="img-fluid" alt="logo" /></a>
                                        <div data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
                                            <div className="item">
                                                <img src={DASH_LOGIN()} className="img-fluid mb-4" alt="logo" />
                                                <h4 className="mb-1 text-white">Tap and Pay</h4>
                                                <p>Est un system de paiement electoniaue adapter à tout le niveau de vie.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LogUp