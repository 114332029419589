import { IMG_SCREEN_1, IMG_SCREEN_2, IMG_SCREEN_3, IMG_SCREEN_4, IMG_SCREEN_5, IMG_SCREEN_6 } from "../components/icons"


const ComponentFuture = (props:any) => {
   return (
    <>
      <section id={props.id} className="overview-block-ptb iq-amazing-tab white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title">
                                    <h2 className="title iq-tw-6">Special Features</h2>
                                    <div className="divider"></div>
                                    <p>Appino is launch with everything you need. We've got a lot of amaing and cool features. so here we go,  with unlimited features. go and check out!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">

                                <ul className="nav nav-tabs" role="tablist">
                                    <li  role="presentation" className="active wow fadeInLeft li1" data-wow-duration="1s">
                                        <a className="round-right" href="#design" aria-controls="design" role="tab" data-toggle="tab">
                                            <div className="iq-fancy-box-01 text-right">
                                                <i aria-hidden="true" className="ion-ios-checkmark-outline"></i>
                                                <h4 className="iq-tw-6">Responsive Design</h4>
                                                <div className="fancy-content-01">
                                                    <p>Lorem ipsum madolor sit amet, consectetur adipisicing sed do eiusmod empor incididunt</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="presentation" className="wow fadeInLeft li1" data-wow-duration="1s" >
                                        <a className="round-right" href="#resolution" aria-controls="resolution" role="tab" data-toggle="tab">
                                            <div className="iq-fancy-box-01 text-right">
                                                <i aria-hidden="true" className="ion-ios-color-wand-outline"></i>
                                                <h4 className="iq-tw-6">Easy to Use</h4>
                                                <div className="fancy-content-01">
                                                    <p>Lorem ipsum madolor sit amet, consectetur adipisicing sed do eiusmod empor incididunt</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="presentation" className="wow fadeInLeft li1" data-wow-duration="1s">
                                        <a className="round-right" href="#ready" aria-controls="ready" role="tab" data-toggle="tab">
                                            <div className="iq-fancy-box-01 text-right">
                                                <i aria-hidden="true" className="ion-ios-copy-outline"></i>
                                                <h4 className="iq-tw-6">Well Documentation</h4>
                                                <div className="fancy-content-01">
                                                    <p>Lorem ipsum madolor sit amet, consectetur adipisicing sed do eiusmod empor incididunt</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 text-center hidden-sm hidden-xs">

                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane active" id="design"><img src={IMG_SCREEN_1()} className="img-responsive center-block" alt="#" /></div>
                                    <div role="tabpanel" className="tab-pane" id="resolution"><img src={IMG_SCREEN_2()} className="img-responsive center-block" alt="#" /></div>
                                    <div role="tabpanel" className="tab-pane" id="ready"><img src={IMG_SCREEN_3()} className="img-responsive center-block" alt="#" /></div>
                                    <div role="tabpanel" className="tab-pane" id="fertures"><img src={IMG_SCREEN_4()} className="img-responsive center-block" alt="#" /></div>
                                    <div role="tabpanel" className="tab-pane" id="face"><img src={IMG_SCREEN_5()} className="img-responsive center-block" alt="#" /></div>
                                    <div role="tabpanel" className="tab-pane" id="codes"><img src={IMG_SCREEN_6()} className="img-responsive center-block" alt="#" /></div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className="wow fadeInRight li1" data-wow-duration="1s">
                                        <a  href="#fertures" aria-controls="fertures" role="tab" data-toggle="tab">
                                            <div className="iq-fancy-box-01">
                                                <i aria-hidden="true" className="ion-ios-photos-outline"></i>
                                                <h4 className="iq-tw-6">Perfect Showcase</h4>
                                                <div className="fancy-content-01">
                                                    <p>Lorem ipsum madolor sit amet, consectetur adipisicing sed do eiusmod empor incididunt</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="presentation" className="wow fadeInRight li1" data-wow-duration="1s">
                                        <a  href="#face" aria-controls="face" role="tab" data-toggle="tab">
                                            <div className="iq-fancy-box-01">
                                                <i aria-hidden="true" className="ion-ios-heart-outline"></i>
                                                <h4 className="iq-tw-6">Unlimited Features</h4>
                                                <div className="fancy-content-01">
                                                    <p>Lorem ipsum madolor sit amet, consectetur adipisicing sed do eiusmod empor incididunt</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="presentation" className="wow fadeInRight li1" data-wow-duration="1s">
                                        <a  href="#codes" aria-controls="codes" role="tab" data-toggle="tab">
                                            <div className="iq-fancy-box-01">
                                                <i aria-hidden="true" className="ion-ios-plus-outline"></i>
                                                <h4 className="iq-tw-6">And Many More</h4>
                                                <div className="fancy-content-01">
                                                    <p>Lorem ipsum madolor sit amet, consectetur adipisicing sed do eiusmod empor incididunt</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
    </>
   )
}

export default ComponentFuture