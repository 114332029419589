import React, { useState } from "react";
import { LOGO_FAYSAL_COMPANY, LOGO_FAYSAL_COMPANY2 } from "../../components/icons";
import { Link } from 'react-scroll'
import BtnAll from "../../components/BtnAll";
import { useNavigate } from "react-router-dom";
import DramerMenu from "./MobileMenu";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const HeaderHorizontal = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate()
    const [click, setClick] = useState(false)
    const closeMenu = () => setClick(true)

    function Login() {
        navigate('/login')
    }
    return (
        <>
            {/* <header id="header-wrap" data-spy="affix" data-offset-top="55">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                        <nav className="navbar navbar-default">

                            <div className="navbar-header">
                                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-nav_1bar-collapse1-1" aria-expanded="false">
                                        <span className="sr-only">Toggle nav_1igation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <Link className="nav_1bar-brand a1" to="/home">
                                        <img src={LOGO_FAYSAL_COMPANY()} alt="logo" />
                                    </Link>
                                </div>

                                <div className="collapse navbar-collapse1" id="bs-example-navbar-collapse1-1">
                                    <ul className="nav navbar-nav_1 navbar-right " id="top-menu">
                                        <li className="active"><Link  to="/home">Acceuil</Link></li>
                                        <li ><Link  to="#">Apropos</Link></li>
                                        <li ><Link  to="#">Future</Link></li>
                                        <li ><a  href="#">Screenshots</a></li>
                                        <li ><a  href="#">Team</a></li>
                                        <li ><a  href="#">Tarif</a></li>
                                        <li ><a  href="#">Blog</a></li>
                                        <li ><Link  to="/dashboard">Contact Us</Link></li>
                                        <li ><Link  to="/account">Creer compte</Link></li>
                                        <li ><Link  to="/login">Se connecter</Link></li>
                                    </ul>
                                </div>

                            </nav>
                        </div>
                    </div>
                </div>
            </header> */}

            <header style={{ backgroundColor: '#CB2522', opacity: 0.9 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav className="navbar navbar-default">

                                {
                                    isMatch ? (
                                        <>
                                            <DramerMenu />
                                        </>
                                    ) : (
                                        <>
                                            <div className="navbar-header">
                                                <Link className="navbar-brand" to="home" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>
                                                    <img src={LOGO_FAYSAL_COMPANY()} alt="logo" />
                                                </Link>
                                            </div>
                                            <div className="collapse navbar-collapse">
                                                <ul className="nav navbar-nav navbar-right">
                                                    <li className="active"><Link to="home" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Acceuil</Link></li>
                                                    {/* <li><Link className={click ? "nav-item active" : "nav-item"} to="apropos" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Apropos</Link></li>
                                                    <li><Link to="future" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Future</Link></li>
                                                    <li><Link to="screenshots" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Screenshots</Link></li>
                                                    <li><Link to="team" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Equipe</Link></li>
                                                    <li><Link to="faq" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu} >Faq</Link></li>
                                                    <li><Link to="pricing" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Tarification</Link></li>
                                                   
                                                    <li><Link to="blog" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu} >Blog</Link></li>
                                                   
                                                    <li><Link to="contact" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}>Contact</Link></li> */}
                                                    <li> <BtnAll onClick={Login} appel="Se connecter" borderRadius="10px" width="120px"
                                                        height="35px" color="#D03A38" backgroundColor="white"
                                                    /></li>
                                                </ul>

                                            </div>
                                        </>
                                    )
                                }

                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderHorizontal