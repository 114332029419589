import { Link } from "react-router-dom"
import { BG } from "../../components/icons"
import HeaderHorizontal from "../menus/HeaderHorizontal"
import Footer from "../menus/Footer"
import ComponentFuture from "../../controllers/ComponentFuture"
import FooterAcceuil from "../menus/FooterAcceuil"

const Future = () => {
   return (
     <>
       <HeaderHorizontal/>
         <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title iq-breadcrumb-title iq-mtb-0" style={{paddingTop:35}}>
                                <h1 className="title iq-tw-8 iq-font-white" >Future</h1>
                                <div className="divider white" ></div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>

            <ComponentFuture/>

            <FooterAcceuil/>

     </>
   )
}

export default Future