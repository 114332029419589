import dash1 from '../assets/images/login/dash1.png';
import logofaysal from '../assets/images/Logo_White.png'
import logofaysal2 from '../assets/images/Logo_Color.png'
import img02 from '../assets/images/error/img02.png'
import baniere1 from '../assets/images/baniere1.png'
import baniere2 from '../assets/images/baniere2.png'
import baniere3 from '../assets/images/baniere3.png'
import android from '../assets/images/android.svg'
import bg from '../assets/assets1/images/banner/bg.jpg'
import icon1 from '../assets/images/icon1.png'
import icon2 from '../assets/images/icon2.png'
import icon3 from '../assets/images/icon3.png'
import about from '../assets/images/about.jpg'
import img_01 from '../assets/images/screenshots/img_01.jpg'
import img_02 from '../assets/images/screenshots/img_02.jpg'
import img_03 from '../assets/images/screenshots/img_03.jpg'
import img_04 from '../assets/images/screenshots/img_04.jpg'
import img_05 from '../assets/images/screenshots/img_05.jpg'
import img_06 from '../assets/images/screenshots/img_06.jpg'
import team_01 from '../assets/images/team/team_01.jpg'
import team_02 from '../assets/images/team/team_02.jpg'
import team_03 from '../assets/images/team/team_03.jpg'
import team_04 from '../assets/images/team/team_04.jpg'
import about_04 from '../assets/images/about/about_04.jpg'
import blog_01 from '../assets/images/blog/blog_01.jpg'
import blog_02 from '../assets/images/blog/blog_02.jpg'
import blog_03 from '../assets/images/blog/blog_03.jpg'
import client_01 from '../assets/images/clients/client_01.png'
import client_02 from '../assets/images/clients/client_02.png'
import client_03 from '../assets/images/clients/client_03.png'
import client_04 from '../assets/images/clients/client_04.png'
import client_05 from '../assets/images/clients/client_05.png'
import client_06 from '../assets/images/clients/client_06.png'
import temoin_01 from '../assets/images/testimonial/temoin_01.jpg'
import temoin_02 from '../assets/images/testimonial/temoin_02.jpg'
import temoin_03 from '../assets/images/testimonial/temoin_03.jpg'
import defaultav from './images/default.png'
import card from '../assets/images/card.png'

export const DASH_LOGIN = () => {
    return dash1
}

export const LOGO_FAYSAL_COMPANY = () => {
    return logofaysal
}

export const LOGO_FAYSAL_COMPANY2 = () => {
    return logofaysal2
}

export const IMAGE_HOME1 = () => {
    return img02
}
export const BANNIERE1 = () => {
    return baniere2
}
export const BANNIERE2 = () => {
    return baniere1
}
export const BANNIERE3 = () => {
    return baniere3
}

export const ICON_1 = () => {
    return android
}
export const BG = () => {
    return bg
}
export const FUTURE_1 = () => {
    return icon1
}
export const FUTURE_2 = () => {
    return icon2
}
export const FUTURE_3 = () => {
    return icon3
}
export const ABOUT = () => {
    return about
}
export const ABOUT_04= () => {
    return about_04
}

// export const PHONE = () => {
//     return phone
// }
export const IMG_SCREEN_1 = () => {
    return img_01
}
export const IMG_SCREEN_2 = () => {
    return img_02
}
export const IMG_SCREEN_3 = () => {
    return img_03
}
export const IMG_SCREEN_4 = () => {
    return img_04
}
export const IMG_SCREEN_5 = () => {
    return img_05
}
export const IMG_SCREEN_6 = () => {
    return img_06
}
export const IMG_TEAM_1 = () => {
    return team_01
}
export const IMG_TEAM_2 = () => {
    return team_02
}
export const IMG_TEAM_3 = () => {
    return team_03
}
export const IMG_TEAM_4 = () => {
    return team_04
}

export const IMG_BLOG_1 = () => {
    return blog_01
}

export const IMG_BLOG_2 = () => {
    return blog_02
}

export const IMG_BLOG_3 = () => {
    return blog_03
}

export const CLIENT_O1 = () => {
    return client_01
}
export const CLIENT_O2 = () => {
    return client_02
}
export const CLIENT_O3 = () => {
    return client_03
}
export const CLIENT_O4 = () => {
    return client_04
}
export const CLIENT_O5 = () => {
    return client_05
}
export const CLIENT_O6 = () => {
    return client_06
}


export const TEMOIN_1 = () => {
    return temoin_01
}
export const TEMOIN_2 = () => {
    return temoin_02
}
export const TEMOIN_3 = () => {
    return temoin_03
}

export const DEFAULT1 = () => {
   return defaultav
}

export const CARD = () => {
    return card
}
