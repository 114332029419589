import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Box } from '@mui/material';
import {IMG_SCREEN_1, IMG_SCREEN_2, IMG_SCREEN_3, IMG_SCREEN_4, IMG_SCREEN_5, IMG_SCREEN_6} from '../icons';

export const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

class Carousel2 extends React.Component {

    render() {
        return (
            <Box sx={{ width: '100%'}}>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    // showDots={true}
                    responsive={responsive}
                    ssr={true}  
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={3000}
                    //  keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    // containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    
                >
                    <div className="item" ><a href={IMG_SCREEN_1()} className="popup-img"><img className="img-responsive" src={IMG_SCREEN_1()} alt="#" /></a></div>
                    <div className="item" ><a href={IMG_SCREEN_2()} className="popup-img"><img className="img-responsive" src={IMG_SCREEN_2()} alt="#" /></a></div>
                    <div className="item" ><a href={IMG_SCREEN_3()} className="popup-img"><img className="img-responsive" src={IMG_SCREEN_3()} alt="#" /></a></div>
                    <div className="item" ><a href={IMG_SCREEN_4()} className="popup-img"><img className="img-responsive" src={IMG_SCREEN_4()} alt="#" /></a></div>
                    <div className="item" ><a href={IMG_SCREEN_5()} className="popup-img"><img className="img-responsive" src={IMG_SCREEN_5()} alt="#" /></a></div>
                    <div className="item" ><a href={IMG_SCREEN_6()} className="popup-img"><img className="img-responsive" src={IMG_SCREEN_6()} alt="#" /></a></div>
                   
                </Carousel>
            </Box>

        )
    }
}

export default Carousel2
