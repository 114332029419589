import { LuWallet } from "react-icons/lu";
import PageHeader from "./adminHeder/pageHeader"
import { useEffect, useState } from "react";
import ModalPayement from "../../controllers/rechargerAccount/modalPayment";
import { useTextContext } from "../../TextProvider";
import { Button, Card, Tag } from "antd";
import api from '../../config/index'
import { InterfaceMobile } from "../../config/Interfaces/InterfaceMobile";
import { useNavigate } from "react-router-dom";
import { Drawer, Typography } from 'antd';

const { Text } = Typography;

const AdminHomePage = () => {
    const [devise, setDevise] = useState('')
    const [transaction, setTransaction] = useState<InterfaceMobile[]>([])
    const [transactionAll, setTransactionAll] = useState<InterfaceMobile[]>([])
    const navigate = useNavigate()
    const [telecom, setTelecom] = useState('')
    const [phone, setPhone] = useState('')
    const [status, setStatus] = useState('')
    const [amount, setAmount] = useState('')
    const [currency, setCurrency] = useState('')
    const [createdAt, setCreatedAt] = useState('')

    useEffect(() => {
        if (sessionStorage.getItem("onktxonktxmmarger") === null) {
            navigate("/login")
        }
        LoadTransaction()
        LoadTransactionAll()
    }, [])



    const LoadTransaction = () => {
        api.getHttpRequest(`/account/load_transaction`, {}, 'GET').then((response) => {
            const json = response.data.data.wallet;
            setTransaction(json)

        }).catch((error) => {
            console.log(error.message);
        })
    }

    const LoadTransactionAll = () => {
        api.getHttpRequest(`/account/load_transaction`, {}, 'GET').then((response) => {
            const json = response.data.data.list.rows;
            setTransactionAll(json)

        }).catch((error) => {
            console.log(error.message);
        })
    }
    const handleTelec = () => {
        LoadTransaction();
    };

    const handleTelec1 = () => {
        LoadTransactionAll();
    };
    const styles = {

        userInfo: {
            fontSize: '1.2em',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        navigationBar: {
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
        },
        summaryBox: {
            border: '1px solid #ccc',
            padding: '10px',
            marginBottom: '10px',
        },
        greenBox: {
            color: 'green',
        },
        redBox: {
            color: 'red',
        },
        revenueAmount: {
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
        dataWidget: {
            backgroundColor: '#fff',
            padding: '10px',
            marginBottom: '10px',
        },
        chart: {
            backgroundColor: '#f9f9f9',
            padding: '10px',
            marginBottom: '10px',
        },
        inputSearch: {
            padding: '5px',
            marginBottom: '10px',
        },
        selectCompany: {
            padding: '5px',
        },
    };
    const isMobileScreen = window.innerWidth <= 768;

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModalPayement = () => {
        setIsModalOpen2(true);

    };

    const showModalPayementBy = (element: any) => {
        setIsModalOpen(true);
        setDevise(element.Currency.id)
    };
    const handleOk1 = () => {
        setIsModalOpen2(false);
        setIsModalOpen(false)
    };

    const handleCancel1 = () => {
        setIsModalOpen2(false);
        setIsModalOpen(false)
    };

    const [drawerVisible, setDrawerVisible] = useState(false);

    const showDrawer = (element: any) => {
        setDrawerVisible(true);
        setAmount(element.amount)
        setCurrency(element.AccountWallet?.Currency?.name)
        setPhone(element.phone)
        setStatus(element.status)
        setCreatedAt(element.createdAt)
        setTelecom(element.Telecom.name)
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        
    };

    const viewStatus = (x: string) => {
        if (x === 'SUCCESS') {
            return <small style={{ color: '#1D681C' }}>success</small>
        }
        if (x === 'ERROR') {
            return <small style={{ color: '#D03A38' }}>error</small>
        }
        if (x === 'VERIFICATION') {
            return <small style={{ color: '#FEA310' }}>verification</small>
        }
    }

    return (
        <>
            <PageHeader />
            <div style={{
                fontFamily: 'Arial, sans-serif',
                backgroundColor: 'white',
                paddingLeft: isMobileScreen ? '5px' : '16%',
                paddingTop: isMobileScreen ? '22%' : '8%',
                paddingRight: '2%',
            }}>
                <div style={styles.userInfo}>DashBoard</div>
                <div style={{
                    backgroundColor: '#F5F5F5',
                    color: '#fff',
                    padding: '10px',
                    borderRadius: 10
                }}>
                    <div className="row">
                        {
                            transaction.length > 0 ? (
                                transaction.map((item, i) => (

                                    <div
                                        className="col-sm-6 col-md-6 col-lg-3"
                                        style={{
                                            backgroundColor: '#D03A38',
                                            padding: 2,
                                            borderRadius: '12px',
                                            border: '1px solid #D03A38',
                                            margin: '0px 10px',
                                            marginBottom: isMobileScreen ? 5 : ''
                                        }}

                                    >
                                        <div style={{ color: 'white' }} className="">
                                            <div className="iq-box-relative" style={{ display: 'flex', paddingTop: 15 }}>
                                                <div><LuWallet style={{ fontSize: 60, color: 'white' }} /></div>
                                                <div className="d-flex align-items-center justify-content-between"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                    }}>
                                                    <div style={{
                                                        padding: '10px',
                                                        marginBottom: '10px',
                                                        marginTop: '-10px',
                                                        marginRight: isMobileScreen ? '90px' : '20px',
                                                    }}>
                                                        Total Solde
                                                        <div style={{
                                                            fontSize: '1.2em',
                                                            fontWeight: 'bold',
                                                        }}>{item.Currency.name}.{item.solde}</div>
                                                        <small>num: {item.account_number}</small>
                                                    </div>
                                                    <div onClick={() => showModalPayementBy(item)} style={{
                                                        width: '70px', backgroundColor: 'white', color: 'black', padding: 5, marginRight: '-25px',
                                                        borderRadius: 5, cursor: 'pointer'
                                                    }}>
                                                        Rechager
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))

                            ) : (
                                <div
                                    className="col-sm-6 col-md-6 col-lg-3"
                                    style={{
                                        backgroundColor: '#D03A38',
                                        padding: 2,
                                        borderRadius: '12px',
                                        border: '1px solid #D03A38',
                                        margin: '0px 10px',
                                        marginBottom: isMobileScreen ? 5 : ''
                                    }}
                                >
                                    <div style={{ color: 'white' }} className="">
                                        <div className="iq-box-relative" style={{ display: 'flex', paddingTop: 15 }}>
                                            <div><LuWallet style={{ fontSize: 60, color: 'white' }} /></div>
                                            <div className="d-flex align-items-center justify-content-between"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}>
                                                <div style={{
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                    marginTop: '-10px',
                                                    marginRight: isMobileScreen ? '90px' : '20px',
                                                }}>
                                                    Total Solde
                                                    <div style={{
                                                        fontSize: '1.2em',
                                                        fontWeight: 'bold',
                                                    }}>$.0</div>
                                                </div>
                                                <div onClick={showModalPayement} style={{
                                                    width: '70px', backgroundColor: 'white', color: 'black', padding: 5, marginRight: '-25px',
                                                    borderRadius: 5, cursor: 'pointer'
                                                }}>
                                                    Rechager
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }



                    </div>
                </div>

                <div>
                    {
                        isMobileScreen ? (
                            <>
                                {transactionAll.map((item, i) => (

                                    <Card
                                        style={{ marginBottom: 10, height: 100 }}
                                        onClick={()=>showDrawer(item)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <div >

                                                <div style={{ marginBottom: 1 }}>
                                                    {item.Telecom.name}.{item.phone}
                                                </div>
                                                <div style={{ marginBottom: 1 }}>
                                                    <Tag style={{ fontWeight: 'bold' }}> </Tag>{viewStatus(`${item.status}`)}
                                                </div>
                                            </div>
                                            <div>
                                                <span style={{ backgroundColor: '#F5F5F5',  padding: 10, borderRadius: 5 }} >
                                                    {item.AccountWallet.Currency.name}.{item.amount}
                                                </span><br/>
                                                <small>{api.geDateFormat(item.createdAt)}</small>
                                            </div>
                                        </div>
                                       

                                    </Card>

                                ))}

                            </>
                        ) : (
                            <>

                                <div className="table-responsive" style={{ marginTop: 5 }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead style={{ backgroundColor: '#D03A38', color: 'white' }}>
                                                            <tr>
                                                                <th scope="col">Numero</th>
                                                                <th scope="col">Montant</th>
                                                                <th scope="col">Telecom</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transactionAll.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td><Tag>{item.AccountWallet.Currency.name}.{item.amount}</Tag></td>
                                                                    <td>{item.Telecom.name}<br />
                                                                        {item.phone}
                                                                    </td>
                                                                    <td>{item.status}</td>
                                                                    <td>{api.geDateFormat(item.createdAt)}<br />
                                                                        {api.formatDate(item.createdAt)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </tr>
                                        </thead>

                                    </table>
                                </div>
                            </>
                        )
                    }

                </div>


            </div>

            <ModalPayement isModalOpen2={isModalOpen} handleOk1={handleOk1} handleCancel1={handleCancel1} title='RECHARGER' dev={devise}
                telec={handleTelec}
                telec1={handleTelec1}
            />
            <ModalPayement isModalOpen2={isModalOpen2} handleOk1={handleOk1} handleCancel1={handleCancel1} title='RECHARGER'
                telec={handleTelec}
                telec1={handleTelec1}
            />


            <Drawer
                title="Détails de la transaction"
                placement="bottom"
                closable={false}
                onClose={closeDrawer}
                visible={drawerVisible}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <div >

                        <div style={{ marginBottom: 1 }}>
                            {telecom}.{phone}
                        </div>
                        <div style={{ marginBottom: 1 }}>
                            <Tag style={{ fontWeight: 'bold' }}> </Tag>{viewStatus(status)}
                        </div>
                    </div>
                    <div>
                        <span style={{ backgroundColor: '#F5F5F5', padding: 10, borderRadius: 5 }} >
                            {currency}.{amount}
                        </span><br/>
                        <small>{api.geDateFormat(createdAt)}</small><br/>
                        <small>{api.formatDate(createdAt)}</small>
                    </div>
                </div>
                
               
            </Drawer>
        </>
    )
}

export default AdminHomePage