import { InterfaceMobile } from "../config/Interfaces/InterfaceMobile";
import { InterfaceProfil } from "../config/Interfaces/InterfaceProfil";
import api from '../config/index'

const LoadTelecom = async (): Promise<InterfaceMobile[]> => {
    try {
        const response = await api.getHttpRequest(`/telecom/load`, {}, "GET")
        const json = response.data.data.rows;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadCurrency = async (): Promise<InterfaceMobile[]> => {
    try {
        const response = await api.getHttpRequest(`/currency/load`, {}, "GET")
        const json = response.data.data.rows;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadTransaction = async (): Promise<InterfaceMobile[]> => {
    try {
        const response = await api.getHttpRequest(`/account/load_transaction`, {}, "GET")
        const json = response.data.data.wallet;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadTransactionAll = async (): Promise<InterfaceMobile[]> => {
    try {
        const response = await api.getHttpRequest(`/account/load_transaction`, {}, "GET")
        const json = response.data.data.list.rows;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadPaiement = async (): Promise<InterfaceMobile[]> => {
    try {
        const response = await api.getHttpRequest(`/account/load_transaction`, {}, "GET")
        const json = response.data.data.list.rows;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadPaiementRecu = async (): Promise<InterfaceMobile[]> => {
    try {
        const response = await api.getHttpRequest(`/account/paiement-history-recue`, {}, "GET")
        const json = response.data.data.list.rows;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadProfil = async (): Promise<InterfaceProfil> => {
    try {
        const response = await api.getHttpRequest(`/user/profil`, {}, "GET")
        const json = response.data.data;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return {
            id: '',
            fullname: '',
            phone: '',
            gender: '',
            email: '',
            token: '',
            avatar: '',
            date_birth: '',
            place_birth: '',
            country: '',
            city: '',
            updatedAt: ''
        };
    }
}

export default { LoadTelecom, LoadCurrency, LoadTransaction, LoadProfil, LoadTransactionAll , LoadPaiement, LoadPaiementRecu}