import PageHeader from "../nouveaudesign/adminHeder/pageHeader";
import api from '../../config/index'
import { Card, Drawer, Tag } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InterfaceMobile } from "../../config/Interfaces/InterfaceMobile";

const PaeimentPage = () => {
    const navigate = useNavigate();
    const [payement, setPayement] = useState<InterfaceMobile[]>([])
    const [compteTrans, setCompteTrans] = useState('')
    const [status, setStatus] = useState('')
    const [amount, setAmount] = useState('')
    const [currency, setCurrency] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [fullname, setFullname] = useState('')
    const [motif, setMotif] = useState('')
    
    useEffect(() => {
        if (sessionStorage.getItem("onktxonktxmmarger") === null) {
            navigate("/login")
        }
        LoadPaiement()
    }, [])

    const LoadPaiement = () => {
        api.getHttpRequest(`/account/paiement-history`, {}, 'GET').then((response) => {
            const json = response.data.data.rows;
            setPayement(json)

        }).catch((error) => {
            console.log(error.message);
        })
    }
    const isMobileScreen = window.innerWidth <= 768;
    const styles = {

        userInfo: {
            fontSize: '1.2em',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        navigationBar: {
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
        },
        summaryBox: {
            border: '1px solid #ccc',
            padding: '10px',
            marginBottom: '10px',
        },
        greenBox: {
            color: 'green',
        },
        redBox: {
            color: 'red',
        },
        revenueAmount: {
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
        dataWidget: {
            backgroundColor: '#fff',
            padding: '10px',
            marginBottom: '10px',
        },
        chart: {
            backgroundColor: '#f9f9f9',
            padding: '10px',
            marginBottom: '10px',
        },
        inputSearch: {
            padding: '5px',
            marginBottom: '10px',
        },
        selectCompany: {
            padding: '5px',
        },
    };

    const [drawerVisible, setDrawerVisible] = useState(false);

    const showDrawer = (element: any) => {
        setDrawerVisible(true);
        setAmount(element.amount)
        setCurrency(element.AccountWalletIdTo.Currency.name)
        setStatus(element.status)
        setCreatedAt(element.createdAt)
        setFullname(element.AccountWalletIdFrom.User.fullname)
        setCompteTrans(element.AccountWalletIdTo.account_number)
        setMotif(element.motif)
    };
    const closeDrawer = () => {
        setDrawerVisible(false);
        
    };

    const viewStatus = (x: string) => {
        if (x === 'SUCCESS') {
            return <small style={{ color: '#1D681C' }}>success</small>
        }
        if (x === 'ERROR') {
            return <small style={{ color: '#D03A38' }}>error</small>
        }
        if (x === 'VERIFICATION') {
            return <small style={{ color: '#FEA310' }}>verification</small>
        }
    }
    return (
        <>
            <PageHeader />
            <div style={{
                fontFamily: 'Arial, sans-serif',
                backgroundColor: 'white',
                paddingLeft: isMobileScreen ? '8px' : '16%',
                paddingTop: isMobileScreen ? '22%' : '8%',
                paddingRight: '2%',
            }}>
                <div style={styles.userInfo}>Liste des Paiements</div>

                {
                    isMobileScreen ? (
                        <>
                            {
                                payement.map((item, i) => (
                                    <Card
                                        style={{ marginBottom: 10, height: 100 }}
                                        onClick={() => showDrawer(item)}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <div >

                                                <div style={{ marginBottom: 1 }}>
                                                {item.AccountWalletIdFrom.User.fullname}
                                                </div>
                                                <div style={{ marginBottom: 1 }}>
                                                    <Tag style={{ fontWeight: 'bold' }}> account_ref : {item.AccountWalletIdTo.account_number}</Tag>{viewStatus(`${item.status}`)}
                                                </div>
                                            </div>
                                            <div>
                                                <span style={{ backgroundColor: '#F5F5F5', padding: 10, borderRadius: 5 }} >
                                                {item.AccountWalletIdTo.Currency.name}.{item.amount}
                                                </span><br />
                                                <small>{api.geDateFormat(item.createdAt)}</small>
                                            </div>
                                        </div>


                                    </Card>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            <div className="table-responsive" style={{ marginTop: 5 }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead style={{ backgroundColor: '#D03A38', color: 'white', borderRadius: 10 }}>
                                                        <tr>
                                                            <th scope="col">Numero</th>
                                                            <th scope="col">Utilisateur</th>
                                                            <th scope="col">Montant</th>
                                                            <th scope="col">Compte Envoie</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {payement.map((item, i) => (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{item.AccountWalletIdFrom.User.fullname}<br />

                                                                    <Tag>account_number : {item.AccountWalletIdTo.account_number}</Tag>
                                                                </td>
                                                                <td><Tag>{item.AccountWalletIdTo.Currency.name}.{item.amount}</Tag></td>

                                                                <td>
                                                                    {/* {item.AccountWalletIdTo.name}<br /> */}

                                                                    <Tag>account_ref : {item.AccountWalletIdTo.account_number}</Tag>
                                                                </td>

                                                                <td>{item.status}</td>
                                                                <td>{api.geDateFormat(item.createdAt)}<br />
                                                                    {api.formatDate(item.createdAt)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </tr>
                                    </thead>

                                </table>
                            </div>
                        </>
                    )
                }

            </div>


            <Drawer
                title="Détails des paiements"
                placement="bottom"
                closable={false}
                onClose={closeDrawer}
                visible={drawerVisible}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <div >

                        <div style={{ marginBottom: 1 }}>
                            {fullname}
                        </div>
                        <div style={{ marginBottom: 1 }}>
                            <Tag style={{ fontWeight: 'bold' }}>Number: {compteTrans}</Tag>{viewStatus(status)}
                        </div>
                    </div>
                    <div>
                        <span style={{ backgroundColor: '#F5F5F5', padding: 10, borderRadius: 5 }} >
                            {currency}.{amount}
                        </span><br/>
                        <small>{api.geDateFormat(createdAt)}</small><br/>
                        <small>{api.formatDate(createdAt)}</small>
                    </div>
                </div>
                <div>
                    <span>Motif :</span><br/>
                  <span>{motif}</span><br/>
                  <Tag>account_ref : {compteTrans}</Tag>
                </div>
               
            </Drawer>
        </>
    )
}

export default PaeimentPage