import Carousel2 from "../components/carousel/Carousel2"
import CarouselTemoin from "../components/carousel/CarouselTemoin"
import { BG } from "../components/icons"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";


const ComponentScreenshots = (props: any) => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <>
            {
                isMatch ? (
                    <>

                        <section className="overview-block-ptb white-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title">
                                            <h2 className="title iq-tw-6">How It Work?</h2>
                                            <div className="divider"></div>
                                            <p>Appino is available with new technology and new creative ideas, which works with high resolutions. There is many more features available with different and unique work.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-3 col-md-3">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-ios-monitor-outline"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">High Resolution</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">01</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 col-md-3 re7-mt-50">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-ios-settings"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Color Schemes</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">02</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-social-googleplus-outline"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Google Fonts</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">03</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-ios-heart-outline"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Clean Codes</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">04</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </section>

                        <section id={props.id} className="iq-app iq-bg iq-bg-fixed iq-font-white" style={{ background: `url(${BG()})` }} >
                            <div className="container-fluid">
                                <div className="row row-eq-height">
                                    <div className="col-md-6 text-left iq-ptb-80 green-bg">
                                        <div className="iq-app-info">
                                            <h2 className="heading-left iq-font-white white iq-tw-6">App Screenshots</h2>
                                            <div className="lead iq-tw-6 iq-mb-20">Appino is here with its best screenshot, from this photo gallery you can get idea about this application.</div>
                                            <h4 className="iq-mt-50 iq-font-white iq-tw-6 iq-mb-15">Awesome Design</h4>
                                            <p className="">Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, Lorem Ipsum is simply dummy text of the printing and typesetting indus</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 iq-app-screen iq-ptb-80">
                                        <div className="home-screen-slide">
                                            <div className="owlcarousel">
                                                <Carousel2 />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>




                        <section className="overview-block-ptb grey-bg iq-loved-customers">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title">
                                            <h2 className="title iq-tw-6">Loved By Our Customers</h2>
                                            <div className="divider"></div>
                                            <p>Our Customer are our priority. we believe in providing best services to them. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="owlcarousel">
                                            <CarouselTemoin />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ) : (
                    <>
                        <section id={props.id} className="iq-app iq-bg iq-bg-fixed iq-font-white" style={{ background: `url(${BG()})` }} >
                            <div className="container-fluid">
                                <div className="row row-eq-height">
                                    <div className="col-md-6 text-left iq-ptb-80 green-bg">
                                        <div className="iq-app-info">
                                            <h2 className="heading-left iq-font-white white iq-tw-6">App Screenshots</h2>
                                            <div className="lead iq-tw-6 iq-mb-20">Appino is here with its best screenshot, from this photo gallery you can get idea about this application.</div>
                                            <h4 className="iq-mt-50 iq-font-white iq-tw-6 iq-mb-15">Awesome Design</h4>
                                            <p className="">Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, Lorem Ipsum is simply dummy text of the printing and typesetting indus</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 iq-app-screen iq-ptb-80">
                                        <div className="home-screen-slide">
                                            <div className="owlcarousel">
                                                <Carousel2 />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="overview-block-ptb white-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title">
                                            <h2 className="title iq-tw-6">How It Work?</h2>
                                            <div className="divider"></div>
                                            <p>Appino is available with new technology and new creative ideas, which works with high resolutions. There is many more features available with different and unique work.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-3 col-md-3">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-ios-monitor-outline"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">High Resolution</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">01</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 col-md-3 re7-mt-50">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-ios-settings"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Color Schemes</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">02</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-social-googleplus-outline"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Google Fonts</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">03</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                                        <div className="iq-fancy-box-02 text-center">
                                            <div className="iq-icon">
                                                <i aria-hidden="true" className="ion-ios-heart-outline"></i>
                                            </div>
                                            <div className="fancy-content">
                                                <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Clean Codes</h5>
                                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                                <span className="iq-big-title">04</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </section>


                        <section className="overview-block-ptb grey-bg iq-loved-customers">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title">
                                            <h2 className="title iq-tw-6">Loved By Our Customers</h2>
                                            <div className="divider"></div>
                                            <p>Our Customer are our priority. we believe in providing best services to them. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="owlcarousel">
                                            <CarouselTemoin />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
        </>
    )
}

export default ComponentScreenshots