import { IMG_BLOG_1, IMG_BLOG_2, IMG_BLOG_3 } from "../components/icons"

const ComponentBlog = (props:any) => {
  return (
    <>
      <section id={props.id} className="overview-block-ptb white-bg iq-blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title">
                                    <h2 className="title iq-tw-6">Latest Blog Post</h2>
                                    <div className="divider"></div>
                                    <p>We have completely updated our work, making it much easier for you to find the information you need. Check out the recently updated work and learn more about application.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="iq-blog-box">
                                    <div className="iq-blog-image clearfix">
                                        <img className="img-responsive center-block" src={IMG_BLOG_1()} alt="#" />
                                    </div>
                                    <div className="iq-blog-detail">
                                        <div className="blog-title"> <a href="blog-single.html"><h5 className="iq-tw-6">Blogpost With Image</h5> </a> </div>
                                        <div className="iq-blog-meta">
                                            <ul className="list-inline">
                                                <li ><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 Aug 2017</a></li>
                                                <li ><a href="#"><i className="fa fa-comment-o" aria-hidden="true"></i> 5</a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-content">
                                            <p>Quae laboriosam sunt consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. hic perspiciatis.</p>
                                        </div>
                                        <div className="blog-button">
                                            <a href="#" className="pull-left iq-tw-6 iq-user"><i className="fa fa-user-circle" aria-hidden="true"></i> Tom Tilak</a>
                                            <a href="#" className="pull-right iq-tw-6">Read More <i className="fa fa-angle-right" aria-hidden="true"></i></a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 re-mt-30">
                                <div className="iq-blog-box">
                                    <div className="iq-blog-image clearfix">
                                        <img className="img-responsive center-block" src={IMG_BLOG_2()} alt="#" />
                                    </div>
                                    <div className="iq-blog-detail">
                                        <div className="blog-title"> <a href="blog-single.html"><h5 className="iq-tw-6">Blogpost With Image</h5> </a> </div>
                                        <div className="iq-blog-meta">
                                            <ul className="list-inline">
                                                <li ><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 Aug 2017</a></li>
                                                <li ><a href="#"><i className="fa fa-comment-o" aria-hidden="true"></i> 5</a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-content">
                                            <p>Quae laboriosam sunt consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. hic perspiciatis.</p>
                                        </div>
                                        <div className="blog-button">
                                            <a href="#" className="pull-left iq-tw-6 iq-user"><i className="fa fa-user-circle" aria-hidden="true"></i> Tom Tilak</a>
                                            <a href="#" className="pull-right iq-tw-6">Read More <i className="fa fa-angle-right" aria-hidden="true"></i></a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 re-mt-30">
                                <div className="iq-blog-box">
                                    <div className="iq-blog-image clearfix">
                                        <img className="img-responsive center-block" src={IMG_BLOG_3()} alt="#" />
                                    </div>
                                    <div className="iq-blog-detail">
                                        <div className="blog-title"> <a href="blog-single.html"><h5 className="iq-tw-6">Blogpost With Image</h5> </a> </div>
                                        <div className="iq-blog-meta">
                                            <ul className="list-inline">
                                                <li ><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 Aug 2017</a></li>
                                                <li ><a href="#"><i className="fa fa-comment-o" aria-hidden="true"></i> 5</a></li>
                                            </ul>
                                        </div>
                                        <div className="blog-content">
                                            <p>Quae laboriosam sunt consectetur, assumenda provident lorem ipsum dolor sit amet, consectetur adipisicing elit. hic perspiciatis.</p>
                                        </div>
                                        <div className="blog-button">
                                            <a href="#" className="pull-left iq-tw-6 iq-user"><i className="fa fa-user-circle" aria-hidden="true"></i> Tom Tilak</a>
                                            <a href="#" className="pull-right iq-tw-6">Read More <i className="fa fa-angle-right" aria-hidden="true"></i></a> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 text-center">
                                <a className="button iq-mt-50" href="blog-2-columns.html">Go To Blog</a>
                            </div>
                        </div>
                    </div>
                </section>
    </>
  )
}

export default ComponentBlog