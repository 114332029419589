import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { InterfaceMobile } from "./config/Interfaces/InterfaceMobile";
import CloudRequest from "./controllers/CloudRequest";
import { InterfaceProfil } from "./config/Interfaces/InterfaceProfil";

interface TextContextProps {
    telecom: InterfaceMobile[];
    setTelecom: React.Dispatch<React.SetStateAction<InterfaceMobile[]>>;
    currency: InterfaceMobile[];
    setCurrency: React.Dispatch<React.SetStateAction<InterfaceMobile[]>>;
    transaction: InterfaceMobile[];
    setTransaction: React.Dispatch<React.SetStateAction<InterfaceMobile[]>>;
    transactionAll: InterfaceMobile[];
    setTransactionAll: React.Dispatch<React.SetStateAction<InterfaceMobile[]>>;
    payement: InterfaceMobile[];
    setPayement: React.Dispatch<React.SetStateAction<InterfaceMobile[]>>;
    payementRecy: InterfaceMobile[];
    setPayementRecy: React.Dispatch<React.SetStateAction<InterfaceMobile[]>>;
    profil: InterfaceProfil;
    setProfil: React.Dispatch<React.SetStateAction<InterfaceProfil>>;
   
}

const TextContext = createContext<TextContextProps | undefined>(undefined);


interface TextProviderProps {
    children: ReactNode;
}

export const TextProvider: React.FC<TextProviderProps> = ({children}) => {
    const [telecom, setTelecom] = useState<InterfaceMobile[]>([]);
    const [currency, setCurrency] = useState<InterfaceMobile[]>([]);
    const [transaction, setTransaction] = useState<InterfaceMobile[]>([]);
    const [transactionAll, setTransactionAll] = useState<InterfaceMobile[]>([]);
    const [payement, setPayement] = useState<InterfaceMobile[]>([]);
    const [payementRecy, setPayementRecy] = useState<InterfaceMobile[]>([]);
    const [profil, setProfil] = useState<InterfaceProfil>({});

    useEffect(() => {
        CloudRequest.LoadTelecom().then(data => setTelecom(data))
        CloudRequest.LoadCurrency().then(data => setCurrency(data))
        CloudRequest.LoadTransaction().then(data => setTransaction(data))
        CloudRequest.LoadTransactionAll().then(data => setTransactionAll(data))
        CloudRequest.LoadProfil().then(data => setProfil(data))
        CloudRequest.LoadPaiement().then(data => setPayement(data))
        CloudRequest.LoadPaiementRecu().then(data => setPayementRecy(data))
    }, []);

    return (
        <TextContext.Provider
            value={{telecom, setTelecom, currency, setCurrency, transaction, setTransaction, profil, setProfil, 
                transactionAll, setTransactionAll, payement, setPayement, payementRecy, setPayementRecy
            }}>
            {children}
        </TextContext.Provider>
    );
}

export const useTextContext = (): TextContextProps => {
    const context = useContext(TextContext);
    if (!context) {
        throw new Error('useTextContext must be used within a TextProvider');
    }
    return context;
};