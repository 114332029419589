import { useState } from "react";
import { BG } from "../../components/icons";
import { Link, useNavigate } from "react-router-dom";
import FooterAcceuil from "../menus/FooterAcceuil";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../config/index'
import { Alert, Space, Spin } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const DefaulPassWord = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [_codeVal, setCodeVal] = useState("");

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");
    const [passwordConfir, setPasswordConfir] = useState("");
    const [password, setPassword] = useState("")

    const Confirmpassword = async (event: any) => {
        event.preventDefault();
        let data = JSON.stringify({
            "password_confir": passwordConfir,
            "password": password,
            "personal_token": sessionStorage.getItem('otokkhsnuemroiepersol')
        });
        setLoading(true);

        if (passwordConfir === '') {
            setShowAlertDanger(true)
            setMessage('Veiller entrer tout les champs');
        } else {
            api.getHttpRequestNotToken(`/user/definePassword`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status === 200) {
                    navigate('/login')
                }
                else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                setShowAlertDanger(true)
                setMessage(api.getMessageError(error))
                setCodeVal('')
            })
        }
        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
        }, 10000);
    }

    return (
        <>

            {
                isMatch ? (

                    <>
                        <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title iq-breadcrumb-title iq-mtb-1" >
                                            <h1 className="title iq-tw-8 iq-font-white" style={{ marginTop: 0 }}>Creation | Mot de passe</h1>
                                            <div className="divider white"></div>
                                        </div>
                                        <ul className="breadcrumb">
                                            <li><Link to="/home"><i className="ion-home"></i> Acceuil</Link></li>
                                            <li className="active"><Link to="/validate" style={{ color: "#D03A38" }}>Validation Otp</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="main-content">

                            <section id="contact-us" className="iq-our-touch" style={{ marginTop: 40 }}>
                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="iq-get-in iq-pall-40 white-bg">

                                                <div className="row">

                                                    <div className="form-horizontal" id="contactform">

                                                        <div className="contact-form">

                                                            <div className="col-sm-2"></div>

                                                            <div className="col-sm-8">
                                                            <div className=" p-2 form-label rounded-sm text-center" style={{
                                                                    backgroundColor: 'white', marginBottom: 5,
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    fontSize: 16
                                                                }}>
                                                                    <small className="font-24 text-center ">
                                                                        Assurez-vous de définir votre mot de passe par défaut pour vous connecter au system
                                                                    </small>
                                                                </div>
                                                                {
                                                                    message && <Space direction="vertical" style={{ width: '100%', marginBottom: 5 }}>
                                                                    {
                                                                        showAlertDanger &&
                                                                        <Alert type="error" message={message} showIcon closable />
                                                                    }
                                                                </Space>
                                                                }
                                                                
                                                                <div className="section-field">
                                                                    <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="password" placeholder="Mot de passe pad defaut" name="name"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="section-field">
                                                                    <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="password" placeholder="Confirmer le mot de passe" name="name"
                                                                        value={passwordConfir}
                                                                        onChange={(e) => setPasswordConfir(e.target.value)}
                                                                    />
                                                                </div>
                                                                {!Loading && (
                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                        onClick={Confirmpassword}
                                                                    >VALIDER OTP</button>
                                                                )}

                                                                {Loading && (

                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                    >
                                                                        <Spin indicator={<AiOutlineLoading3Quarters style={{ fontSize: 24, color: 'white' }} />} />
                                                                    </button>
                                                                )}
                                                            </div>

                                                            <div className="col-sm-2"></div>
                                                        </div>
                                                    </div>
                                                    <div id="ajaxloader" style={{ display: 'none' }}><img className="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <FooterAcceuil />

                        </div>
                    </>
                ) : (
                    <>
                        <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title iq-breadcrumb-title iq-mtb-0">
                                            <h1 className="title iq-tw-8 iq-font-white">Creation | Mot de passe</h1>
                                            <div className="divider white" ></div>
                                        </div>
                                        <ul className="breadcrumb" style={{ marginTop: 1 }}>
                                            <li><Link to="/dashboard"><i className="ion-home"></i> Acceuil</Link></li>
                                            <li className="active" ><Link to="/account" style={{ color: "#D03A38" }}>Pas de compte ? Creer un compte</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <div className="main-content pt-10" style={{ marginTop: 140 }}>

                            <section id="contact-us" className="iq-our-touch">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="iq-get-in iq-pall-40 white-bg">

                                                <div className="row">

                                                    <div className="form-horizontal" id="contactform" >
                                                        <div className="contact-form">
                                                            <div className="col-sm-2"></div>

                                                            <div className="col-sm-8">
                                                                <div className=" p-2 form-label rounded-sm text-center" style={{
                                                                    backgroundColor: 'white', marginBottom: 5,
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    fontSize: 16
                                                                }}>
                                                                    <small className="font-24 text-center ">
                                                                        Assurez-vous de définir votre mot de passe par défaut pour vous connecter au system
                                                                    </small>
                                                                </div>
                                                                {
                                                                    message && <div className=' mb-1'>
                                                                        {/* <label style={{color:'white'}}>vouchers</label> */}
                                                                        <Space direction="vertical" style={{ width: '100%', }}>
                                                                            {
                                                                                showAlertDanger &&
                                                                                <Alert type="error" message={message} showIcon closable />
                                                                            }
                                                                        </Space>

                                                                    </div>
                                                                }
                                                                <div className="section-field">
                                                                    <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="password" placeholder="Mot de passe par defaut" name="name"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="section-field">
                                                                    <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="password" placeholder="Confirmer le mot de passe" name="name"
                                                                        value={passwordConfir}
                                                                        onChange={(e) => setPasswordConfir(e.target.value)}
                                                                    />
                                                                </div>

                                                                {!Loading && (
                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                        onClick={Confirmpassword}
                                                                    >VALIDER OTP</button>
                                                                )}

                                                                {Loading && (

                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                    >
                                                                        <Spin indicator={<AiOutlineLoading3Quarters style={{ fontSize: 24, color: 'white' }} />} />
                                                                    </button>
                                                                )}

                                                            </div>

                                                            <div className="col-sm-2"></div>
                                                        </div>
                                                    </div><br />
                                                    <div>
                                                        <div style={{ display: 'none' }}><img className="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="" /></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <FooterAcceuil />

                        </div>
                    </>
                )
            }


        </>
    )
}

export default DefaulPassWord