import axios from 'axios';
import CryptoJS from 'crypto-js';
import { formatDistanceStrict } from 'date-fns';
import { fr } from 'date-fns/locale';



export const URL_API = process.env.REACT_APP_BASE_URL;
export const FILE_URL = process.env.REACT_APP_FILE_URL;

const url_api_dev = URL_API;

const var_ = {
	url_api_local: url_api_dev,
	url_avatar: FILE_URL,

	token: null,
};



const key = 'ma_clé_de_chiffrement_token_user';


let token_Cripter: string | any = sessionStorage.getItem('onktxonktxmmarger');

let token_Decripter: any;
export const initToken = () => {
		return  sessionStorage.getItem('onktxonktxmmarger');
};

const getHttpRequest = async (
	route: any,
	data: any,
	method = 'POST',
	content_type = 'application/json'
) => {
	initToken();
	const url = var_.url_api_local + route;

	const config_api = {
		method: method,
		url: url,
		headers: {
			'Content-Type': content_type,
			Authorization:
				sessionStorage.getItem('onktxonktxmmarger') == null ||
				sessionStorage.getItem('onktxonktxmmarger') == undefined
					? ''
					: `Bearer ${initToken()}`,
			accept: 'application/json',
		},
		data: data,
	};

	return await axios.request(config_api);
};

const getHttpRequestNotToken = async (
	route: any,
	data: any,
	method = 'POST',
	content_type = 'application/json'
) => {
	const url = var_.url_api_local + route;
	// console.log("Request : " + url)
	const config_api = {
		method: method,
		url: url,
		headers: {
			'Content-Type': content_type,
			Authorization: null,
			accept: 'application/json',
		},
		data: data,
	};

	return await axios.request(config_api);
};

// function geDateFormat(dateString: string): string {
//     const date = new Date(Date.parse(dateString));
//     const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
//     const formattedDate = date.toLocaleDateString('en-US', options);
//     return formattedDate;
// }

function geDateFormat(dateString: string): string {
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
	  return 'Invalid date';
	}
	const now = new Date();
	const relativeDate = formatDistanceStrict(date, now, { addSuffix: true, locale: fr });
	return relativeDate;
  }

function ReturnVoid0(event: any) {
	event.preventDefault();
}

function formatDate(dateString: string): string {
	const date = new Date(dateString);
	const options: any = {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
	};
	const formattedDate = `${date.toLocaleDateString('en-US', options)}`;
	return formattedDate;
}

export const getMessageError = (error: any): string | any => {
	let code = 200;
	let message = 'An unexpected error occurred.';
	if (error.response) {
		code = error.response.status;
		message = error.response.data.message;
	} else if (error.code === 'ECONNABORTED') {
		return 'The internet connection has timed out.';
	} else if (error.code === 'ENOTFOUND' || error.code === 'EAI_AGAIN') {
		return 'Unable to connect to the Internet.';
	} else if (error.code === 'ETIMEDOUT') {
		return 'The internet connection has timed out due to an exceeded wait time.';
	} else if (error.code === 'ECONNREFUSED') {
		return 'The connection was denied by the remote server.';
	} else if (error.code === 'ENETUNREACH') {
		return 'The network is not accessible.';
	} else if (error.code === 'EHOSTUNREACH') {
		return 'The remote host is not reachable.';
	} else if (error.code === 'ECONNRESET') {
		return 'The connection has been reset by the remote peer.';
	}
	switch (code) {
		case 200:
			return message;
		case 401:
			// return (window.location.href = url_redirection_login_local);
            return message;
		case 403:
			// return (window.location.href = url_redirection_login_local);
            return message;
		case 404:
			return message;
		case 409:
			return message;
		case 422:
			return ;
		case 500:
			return message;
		case 400:
			return message;
		default:
			return message;
	}
};

export default {
	var_,
	getHttpRequest,
	getHttpRequestNotToken,
	initToken,
	geDateFormat,
	ReturnVoid0,
	formatDate,
	getMessageError
}
