import React from 'react'

interface IButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  name?: string;
  color?: string;
  size?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  appel?: string;
  margin?: string;
  marginTop?: string;
  borderColor?: string;
}

const BtnAll = (props: IButtonProps) => {
  return (
    <button onClick={props.onClick} 
    name={props.name} 
    style={{color: props.color ? props.color : 'black', 
    fontSize: props.size ? props.size : '16px', 
    backgroundColor: props.backgroundColor ? props.backgroundColor : 'inherit', 
    width: props.width ? props.width : '100px', height: props.height ? props.height : '50px', 
    borderRadius: props.borderRadius ? props.borderRadius : '0px', marginLeft: props.margin ? props.margin: '1px',
     marginTop: props.marginTop ? props.marginTop:'0px', 
     borderColor:props.borderColor ? props.borderColor:'inherit'
    }}
    >
      {props.appel}
    </button>
  );
}

export default BtnAll
