import React from "react";

const ModalVideo = (props) => {
   let url = props.show ? 'https://www.youtube.com/embed/6stylR4bgf4' : ''
   return (
      <div className="modal-wrapper"
         style={{
            display: props.show ? 'block' : 'none',
            opacity: props.show ? '1' : '0'
         }}
      >
         <div className="modal-header">
            <h2>Tap and Pay</h2>
            <span className="close-modal-btn" onClick={props.close}>x</span>
         </div>
         <div className="modal-body">
            <iframe className="video-scr" src={url}
               allow="autoplay: encrypted-media"
               title="video"
               style={{
                  display: props.show ? 'block' : 'none',
                  opacity: props.show ? '1' : '0'
               }}
            />
         </div>
      </div>
   );
}

export default ModalVideo;