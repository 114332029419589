import { Link } from "react-router-dom"
import { BG } from "../../components/icons"
import Footer from "../menus/Footer"
import ComponentAbout from "../../controllers/ComponentAbout"
import HeaderHorizontal from "../menus/HeaderHorizontal"
import ModalVideo from "../../components/modal"
import { useState } from "react"
import FooterAcceuil from "../menus/FooterAcceuil"

const Apropos = () => {

    return (
        <>
          <HeaderHorizontal/>
            <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title iq-breadcrumb-title iq-mtb-0" style={{paddingTop:30}}>
                                <h1 className="title iq-tw-8 iq-font-white">Aporpos</h1>
                                <div className="divider white" ></div>
                            </div>
                            {/* <ul className="breadcrumb" style={{ marginTop: 1 }}>
                                <li><Link to="/dashboard"><i className="ion-home"></i> Acceuil</Link></li>
                                <li className="active" ><Link to="/account" style={{ color: "#D03A38" }}>Pas de compte ? Creer un compte</Link></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </section>

            <ComponentAbout/>

            
            <FooterAcceuil/>


        </>
    )
}

export default Apropos