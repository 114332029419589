
const ComponentPrix = (props:any) => {
   return (
      <>
        <section id={props.id} className="overview-block-ptb grey-bg iq-price-table">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title">
                                    <h2 className="title iq-tw-6">Price</h2>
                                    <div className="divider"></div>
                                    <p>We're here with best pricing offers. Get start your business with our awesome pricing plan. Here is affordable prices available. Go with your choice and enjoy the services.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-3">
                                <div className="iq-pricing text-center">
                                    <div className="price-title green-bg ">
                                        <h1 className="iq-font-white iq-tw-7"><small>$</small>19<small>/Month</small></h1>
                                        <span className="text-uppercase iq-tw-6 iq-font-white">BASIC</span>
                                    </div>
                                    <ul>
                                        <li >100 MB Disk Space</li>
                                        <li >2 Subdomains</li>
                                        <li >5 Email Accounts</li>
                                        <li >Webmail Support</li>
                                        <li >Customer Support 24/7</li>
                                    </ul>
                                    <div className="price-footer">
                                        <a className="button" href="# ">Purchase</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 re7-mt-50">
                                <div className="iq-pricing text-center">
                                    <div className="price-title green-bg ">
                                        <h1 className="iq-font-white iq-tw-7"><small>$</small>29<small>/Month</small></h1>
                                        <span className="text-uppercase iq-tw-6 iq-font-white">STANDARD</span>
                                    </div>
                                    <ul>
                                        <li >100 MB Disk Space</li>
                                        <li >2 Subdomains</li>
                                        <li >5 Email Accounts</li>
                                        <li >Webmail Support</li>
                                        <li >Customer Support 24/7</li>
                                    </ul>
                                    <div className="price-footer">
                                        <a className="button" href="# ">Purchase</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 re-mt-30 wow flipInY" data-wow-duration="1s">
                                <div className="iq-pricing text-center">
                                    <div className="price-title green-bg ">
                                        <h1 className="iq-font-white iq-tw-7"><small>$</small>49<small>/Month</small></h1>
                                        <span className="text-uppercase iq-tw-6 iq-font-white">ADVANCE</span>
                                    </div>
                                    <ul>
                                        <li >100 MB Disk Space</li>
                                        <li >2 Subdomains</li>
                                        <li >5 Email Accounts</li>
                                        <li >Webmail Support</li>
                                        <li >Customer Support 24/7</li>
                                    </ul>
                                    <div className="price-footer">
                                        <a className="button" href="# ">Purchase</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 re-mt-30">
                                <div className="iq-pricing text-center">
                                    <div className="price-title green-bg ">
                                        <h1 className="iq-font-white iq-tw-7"><small>$</small>99<small>/Month</small></h1>
                                        <span className="text-uppercase iq-tw-6 iq-font-white">UNLIMITED</span>
                                    </div>
                                    <ul>
                                        <li >100 MB Disk Space</li>
                                        <li >2 Subdomains</li>
                                        <li >5 Email Accounts</li>
                                        <li >Webmail Support</li>
                                        <li >Customer Support 24/7</li>
                                    </ul>
                                    <div className="price-footer">
                                        <a className="button" href="# ">Purchase</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      </>
   )
}

export default ComponentPrix