import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    IconButton,
    ListItemIcon,
    Slide
} from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from "react-router-dom";
import { TransitionProps } from '@mui/material/transitions';
import { type } from 'os';
import { LOGO_FAYSAL_COMPANY, LOGO_FAYSAL_COMPANY2 } from '../../components/icons';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return < Slide direction='up' ref={ref} {...props} />;
});

const DramerMenu = () => {
    type Anchor = 'top' | 'left' | 'bottom' | 'right'

    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    })
    const handleclickAcceuil = () => {
        navigate('/home')
        setOpenDrawer(false);
    }

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setState({ ...state, [anchor]: open })
            };

    return (
        <>
            <Drawer open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                style={{ width: 400 }}
            >
                <div className='navbar-header mt-50 mb-20' >
                    <Link to="/home"   >
                        <img src={LOGO_FAYSAL_COMPANY2()} alt="logo" width="150" style={{ marginLeft: 10, marginTop: 5 }} />
                    </Link>
                </div>
                <List>
                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/home', { replace: true })}><span style={{ color: '#000000', fontSize: 16, marginLeft: 15 }}>Acceuil</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{ marginTop: 0, marginLeft: 15, marginBottom: 0 }} />

                    {/* <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/apropos', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Apropos</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:0}}/>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/future', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Future</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:0}}/>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/screen', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Screenshots</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:0}}/>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText  onClick={() => navigate('/team', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Equipes</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:0}}/>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/tarif', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Tarification</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:0}}/>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/blog', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Blog</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:0}}/>

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText  onClick={() => navigate('/contact', { replace: true })}><span style={{color: '#000000', fontSize:16, marginLeft:15}}>Contacts</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{marginTop:0,marginLeft:15, marginBottom:40}}/> */}

                    <ListItemButton onClick={() => setOpenDrawer(false)}>
                        <ListItemIcon>
                            <ListItemText onClick={() => navigate('/Login', { replace: true })} style={{
                                backgroundColor: '#D03A38',
                                color: 'white', width: 230, height: 40, textAlign: 'center', borderRadius: 10,
                                paddingTop: 10, marginLeft: 15
                            }}><span style={{ fontSize: 16 }}>SE CONNECTER</span></ListItemText>
                        </ListItemIcon>
                    </ListItemButton><hr style={{ marginTop: 0, marginLeft: 15, marginBottom: 0 }} />
                </List>
            </Drawer>
            {/* <IconButton sx={{ marginLeft: "auto" }} onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
            </IconButton> */}
            <div className="navbar-header">
            
                <button onClick={() => setOpenDrawer(!openDrawer)} type="button" className="navbar-toggle collapsed" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    
                </button>
                

                <Link className="navbar-brand" to="home" >
                    <img src={LOGO_FAYSAL_COMPANY()} alt="logo" />
                </Link>

                <Link className="navbar-brand" style={{color:'white', paddingLeft:'150px', paddingTop:'6%'}} to="/login">Login</Link>
                
            </div>
        </>
    )
}

export default DramerMenu
