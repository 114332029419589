import { Alert, Modal, Space } from 'antd'
import PhoneInput from 'react-phone-input-2'
import { useEffect, useState } from 'react';
import api from '../../config/index'
import Swal from 'sweetalert2';
import { useTextContext } from '../../TextProvider';
import 'react-phone-input-2/lib/style.css';

interface ModalPayementProps {
    isModalOpen2?: boolean;
    handleOk1: () => void;
    handleCancel1: () => void;
    title?: string;
    telec?: () => void;
    telec1?: () => void;
    dev?: string;
}

const ModalPayement: React.FC<ModalPayementProps> = ({
    isModalOpen2,
    handleOk1,
    handleCancel1,
    title,
    telec,
    telec1,
    dev,
}) => {
    const { telecom, currency, transaction, profil } = useTextContext();

    const [loading, setLoading] = useState(false);

    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");

    const [phone, setPhone] = useState<string>('');
    const [amount, setAmount] = useState('');
    const [devise, setDevise] = useState('');
    const [telecom_, setTelecom_] = useState('');
    useEffect(() => {
        setPhone(`${profil.phone}`)
        setDevise(`${dev}`);
    }, [telecom, currency, profil, transaction])

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };


    const Add_Payement = () => {
        let data = JSON.stringify({
            "amount": amount,
            "phone": phone,
            "TelecomId": telecom_,
            "CurrencyId": devise
        });

        setLoading(true);
        if (amount === '' || phone === '' || telecom_ === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true);
            setMessage('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/account/deposit`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: json.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    handleCancel1();
                    {telec && telec()}
                    {telec1 && telec1()};
                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                setShowAlertDanger(true);
                setMessage(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 3000);
    }

    return (
        <>
            <Modal
                title={`PAYEMENT MODE ${title}`}
                open={isModalOpen2} onOk={handleOk1} onCancel={handleCancel1}
                footer={null}
                width={600}
            >
                <div>
                    <div className=" p-2 form-label rounded-sm" role="alert" style={{ backgroundColor: '#EDEDED' }}>
                        <small className="font-24 text-center font-weight-normal">
                            Avant d’effectuer cette opération, assurez-vous d’avoir des fonds dans votre mobile money en fonction de l’opérateur sélectionné
                        </small>
                    </div>

                    {
                        message && (
                            <div className='col-md-12 mb-1 mt-5'>
                                {/* <label style={{color:'white'}}>vouchers</label> */}
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    {
                                        showAlertDanger &&
                                        <Alert type="error" message={message} showIcon closable />
                                    }
                                </Space>
                            </div>
                        )
                    }

                    <div className="">
                        <div className="">
                            <div className="">

                                <div className="" style={{ width: '100%' }}>

                                    <div className="basic-form " style={{ width: '100%' }}>
                                        <div className='row '>
                                            <div className='col-md-12'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label" style={{ color: 'black' }} >Montant</label>
                                                        <input type="text" id="montant" className="default-select form-control wide"
                                                            placeholder="0.00"
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label" style={{ color: 'black' }}>Telecom</label>

                                                        <select className="default-select form-control wide"
                                                            value={telecom_}
                                                            onChange={(e) => setTelecom_(e.target.value)}
                                                        >
                                                            <option>Selectionner</option>
                                                            {
                                                                telecom.map((item, i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='col-md-6'>
                                                    <div className="mb-3">
                                                        <label className="form-label" style={{ color: 'black' }}>Devise</label>

                                                        <select className="default-select form-control wide"
                                                            value={devise}
                                                            onChange={(e) => setDevise(e.target.value)}
                                                        >
                                                            <option></option>
                                                            {
                                                                currency.map((item, i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <div className=" p-1 rounded border border-primaryx" style={{ marginBottom: '10px' }}>

                                                        <div >
                                                            <label className="form-label" style={{ color: 'black' }}>Phone number</label>
                                                            <PhoneInput
                                                                inputStyle={{
                                                                    width: '100%',

                                                                } as React.CSSProperties}
                                                                inputClass={'form-control'}
                                                                country={'cd'}
                                                                value={phone}
                                                                onChange={changePhoneNumber}
                                                                enableSearch={true}
                                                                countryCodeEditable={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="" >
                                    <button style={{ width: '100%', backgroundColor: '#D03A38', borderColor: '#D03A38' }} type="button" className="btn btn-primary agree-link font-18"
                                        id="btn-Withdraw"
                                        onClick={() => Add_Payement()}
                                    >{title}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export default ModalPayement