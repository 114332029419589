import React from "react";
import { LOGO_FAYSAL_COMPANY2 } from "../../components/icons";

const Footer = () => {
   return (
      <>
          <footer className="iq-footer1 white-bg text-center footer1">
                <div className="container">
               
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer-copyright iq-ptb-20">Copyright @ <span id="copyright"> <script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <a href="#" className="text-green">FaysalCompany.</a> Tout droit réserver </div>
                        </div>
                    </div>
                </div>
            </footer>

      </>
   )
}

export default Footer