import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Box } from '@mui/material';
import { CLIENT_O1, CLIENT_O2, CLIENT_O3, CLIENT_O4, CLIENT_O5, CLIENT_O6, IMG_BLOG_1, IMG_SCREEN_1, IMG_SCREEN_2, IMG_SCREEN_3, IMG_SCREEN_4, IMG_SCREEN_5, IMG_SCREEN_6, LOGO_FAYSAL_COMPANY2, TEMOIN_1, TEMOIN_2, TEMOIN_3 } from '../icons';

export const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

class CarouselTemoin extends React.Component {

    render() {
        return (
            <Box sx={{ width: '100%' }}>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    // showDots={true}
                    responsive={responsive}
                    // ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={5000}
                    // keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    // containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                     
                >
                    <div className="item" style={{marginRight:8}}>
                        <div className="iq-client white-bg">
                            <div className="client-img">
                                <img alt="#" className="img-responsive img-circle" src={TEMOIN_1()} />
                            </div>
                            <div className="client-info">
                                <div className="client-name iq-mb-10">
                                    <h5 className="iq-tw-6">Jason Adams</h5>
                                    <span className="sub-title iq-tw-6">CEO, Appino</span>
                                </div>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item" style={{marginRight:8}}>
                        <div className="iq-client white-bg">
                            <div className="client-img">
                                <img alt="#" className="img-responsive img-circle" src={TEMOIN_2()} />
                            </div>
                            <div className="client-info">
                                <div className="client-name iq-mb-10">
                                    <h5 className="iq-tw-6">Amy Adams</h5>
                                    <span className="sub-title iq-tw-6">CEO, Appino</span>
                                </div>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="iq-client white-bg">
                            <div className="client-img">
                                <img alt="#" className="img-responsive img-circle" src={TEMOIN_3()} />
                            </div>
                            <div className="client-info">
                                <div className="client-name iq-mb-10">
                                    <h5 className="iq-tw-6">John Deo</h5>
                                    <span className="sub-title iq-tw-6">CEO, Appino</span>
                                </div>
                                <p>Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor coli incididunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </Box>

        )
    }
}

export default CarouselTemoin
