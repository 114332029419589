import { LOGO_FAYSAL_COMPANY2 } from "../../components/icons";

const FooterAcceuil = () => {
    return (
        <>
            {  /**  Footer --> */}

            <footer className="iq-footer1 white-bg text-center footer1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="footer-info iq-mt-50 iq-mb-30">
                                <img id="logo_img" className="img-responsive center-block iq-mb-10 wow zoomIn" data-wow-duration="1s" src={LOGO_FAYSAL_COMPANY2()} alt="" />
                                <p>Un système de paiement électronique pratique et sécurisé qui vous permet de régler vos achats en un clin d'œil. Fini les tracas liés aux espèces et aux cartes de crédit, car avec Tap and Pay,
                                     tout ce dont vous avez besoin, c'est votre smartphone ou votre montre connectée</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer-copyright iq-ptb-20">Copyright @ <span id="copyright"> <script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <a href="#" className="text-green">FaysalCompany.</a> Tout droit réserver </div>
                        </div>
                    </div>
                </div>
            </footer>

            {  /**  Footer End --> */}
        </>
    );
}

export default FooterAcceuil