import React ,{useEffect, useState} from "react";
import { ABOUT } from "../components/icons"
import ModalVideo from "../components/modal";

const ComponentAbout = (props:any) => {
    const [colaps, setColaps] = useState(false)

     
    const showvideo = () => {
      setColaps(true)
    }

    const hidevideo = () => {
       setColaps(false)
    }

   return (
    <>
        <section id={props.name} className="iq-about grey-bg iq-mtb-40">
                    <div className="container">
                        <div className="row row-eq-height">
                            <div className="col-sm-12 col-lg-5 col-md-5 iq-about-bg">
                                <div className="iq-bg about-img popup-gallery play-video">
                                    <img className="img-responsive center-block" src={ABOUT()} alt="#" />
                                    <button onClick={showvideo} className="iq-video popup-youtube a1"><i className="ion-ios-play-outline"></i></button>
                                    <div className="iq-waves">
                                        <div className="waves wave-1"></div>
                                        <div className="waves wave-2"></div>
                                        <div className="waves wave-3"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-7 col-md-7 iq-pall-50">
                                <h2 className="heading-left iq-tw-6 ">Apropos de l'Application</h2>
                                <div className="lead iq-tw-6 iq-mb-20">This Appino application delivers the most trusted services for your bussiness to succeed in the global app environment.</div>
                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, naking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                                <a className="button iq-mt-15" href="# ">Download</a>
                            </div>
                        </div>
                    </div>
                </section>


                
            <div className="new-video">
                {colaps ? <div onClick={hidevideo} className="back-shed"></div>: null}
                <ModalVideo
                 className="modal"
                 show={colaps}
                 close={hidevideo}
                ></ModalVideo>
            </div>
    </>
   )
}

export default ComponentAbout