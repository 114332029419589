import React from "react";

const dash = React.lazy(() => import('./views/Admin'))
const histo = React.lazy(() => import('./views/History'))
const params = React.lazy(() => import('./views/Parametter'))

const routes = [
    {path: '/dashboard', exact : true, name: 'Admin', element: dash},
    {path: '/history', exact : true, name : 'History', element : histo},
    {path: '/params', exact : true, name : 'Parametter', element : params}
]

export default routes;