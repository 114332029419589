import React, { useEffect, useState } from "react";
import HeaderHorizontal from "./menus/HeaderHorizontal";
import { ABOUT, ABOUT_04, BANNIERE1, BANNIERE2, BANNIERE3, BG, FUTURE_1, FUTURE_2, FUTURE_3, IMAGE_HOME1, IMG_BLOG_1, IMG_BLOG_2, IMG_BLOG_3, IMG_SCREEN_1, IMG_SCREEN_2, IMG_SCREEN_3, IMG_SCREEN_4, IMG_SCREEN_5, IMG_SCREEN_6, IMG_TEAM_1, IMG_TEAM_2, IMG_TEAM_3, IMG_TEAM_4, LOGO_FAYSAL_COMPANY, LOGO_FAYSAL_COMPANY2 } from "../components/icons";
import AdbIcon from '@mui/icons-material/Adb';
import AppleIcon from '@mui/icons-material/Apple';
import HelpIcon from '@mui/icons-material/Help';

import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Footer from "./menus/Footer";

import Carousel1 from "../components/carousel/Carousel1";
import Carousel2 from "../components/carousel/Carousel2";
import CarouselTemoin from "../components/carousel/CarouselTemoin";
import FooterAcceuil from "./menus/FooterAcceuil";
import ModalVideo from "../components/modal";
import Popup from "../controllers/PopVideo";
import ComponentAbout from "../controllers/ComponentAbout";
import ComponentFuture from "../controllers/ComponentFuture";
import ComponentScreenshots from "../controllers/ComponentScreenshots";
import ComponentTeam from "../controllers/ComponentTeam";
import ComponentPrix from "../controllers/ComponentPrix";
import ComponentBlog from "../controllers/ComponentBlog";
import ComponentContact from "../controllers/ComponentContact";
import ComponentFaq from "../controllers/ComponentFaq";
import ModalPayement from "../controllers/rechargerAccount/modalPayment";



const Home = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    

    return (
        <>

            <HeaderHorizontal />

            <section id="home" className="banner iq-bg iq-bg-fixed iq-box-shadow iq-over-black-90" style={{ background: `url(${BG()})` }}>
                <div id="carousel-example-generic" data-ride="carousel">

                    <div className="container">
                        <div className="" role="listbox">
                            <div className="item active">
                                <div className="banner-text">
                                    <div className="row">
                                        <div className="col-sm-8 col-lg-8 col-md-8">
                                            <h1 className="iq-font-white iq-tw-8" data-animation="animated fadeInLeft">
                                                <small className="iq-font-white iq-tw-5"> <strong className="iq-font-white">&#9679;</strong> système de paiement électronique pratique</small>TAP & PAY</h1>
                                            <div className="link">
                                                <h5 className="iq-font-white" data-animation="animated fadeInLeft">Téléchargement gratuit ici</h5>
                                                <ul className="list-inline" data-animation="animated fadeInUp">
                                                    <li ><a href="#"><i className="ion-social-apple"></i></a></li>
                                                    <li ><a href="#"><i className="ion-social-android"></i></a></li>
                                                    <li ><a href="#"><i className="ion-social-windows"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-lg-4 col-md-4 hidden-xs">
                                            <div className="img-one pull-right">
                                                <img className="img-responsive" data-animation="animated fadeInRight" style={{ height: 403, width: 792 }} src={BANNIERE2()} alt="#" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="main-content">

                <section id="future" className="overview-block-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title">
                                    <h2 className="title iq-tw-6">Amazing Features</h2>
                                    <div className="divider"></div>
                                    <p>We've been working really hard to improve the appino with this amazing new features that you aked for! Check out the appino’s new  amazing features. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="iq-fancy-box text-left">
                                    <img src={FUTURE_1()} alt="#" />
                                    <h4 className="iq-tw-6">Easy to Customize</h4>
                                    <p>Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 re-mt-30">
                                <div className="iq-fancy-box text-left">
                                    <img src={FUTURE_2()} alt="#" />
                                    <h4 className="iq-tw-6">Multipurpose layout</h4>
                                    <p>Simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 re-mt-30">
                                <div className="iq-fancy-box text-left">
                                    <img src={FUTURE_3()} alt="#" />
                                    <h4 className="iq-tw-6">Unique Design</h4>
                                    <p>Dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is simplytypesetting industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <ComponentAbout name="apropos" />


                <ComponentFuture id="future" />

                <ComponentScreenshots id="screenshots" />

                <ComponentTeam id="team" />

                <div className="iq-ptb-70 iq-counter-box iq-bg iq-bg-fixed iq-over-black-80" style={{ background: `url(${ABOUT_04()})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-3 col-xs-6 text-center">
                                <div className="counter"> <i className="ion-ios-folder-outline iq-font-white" aria-hidden="true"></i> <span className="timer iq-tw-6 iq-font-black" data-to="1540" data-speed="10000">1540</span>
                                    <label className="iq-font-white">Project</label>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-6 text-center">
                                <div className="counter"> <i className="ion-ios-paper-outline iq-font-white" aria-hidden="true"></i> <span className="timer iq-tw-6 iq-font-black" data-to="2530" data-speed="10000">2530</span>
                                    <label className="iq-font-white">AWARDS</label>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-6 text-center re7-mt-50">
                                <div className="counter"> <i className="ion-ios-person-outline iq-font-white" aria-hidden="true"></i> <span className="timer iq-tw-6 iq-font-black" data-to="8120" data-speed="10000">8120</span>
                                    <label className="iq-font-white">HAPPY CLIENTS</label>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-6 text-center re7-mt-50">
                                <div className="counter"> <i className="ion-ios-star iq-font-white" aria-hidden="true"></i> <span className="timer iq-tw-6 iq-font-black" data-to="1620" data-speed="10000">1620</span>
                                    <label className="iq-font-white">TOTAL APP RATES</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ComponentFaq id="faq" />


                <section className="overview-block-ptb iq-bg iq-bg-fixed iq-over-black-80" style={{ background: `url(${ABOUT_04()})` }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title iq-mb-40">
                                    <h2 className="title iq-tw-6 iq-font-white">Download App</h2>
                                    <div className="divider white"></div>
                                    <p className="iq-font-white">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <a className="button button-icon iq-mr-15" href="# "> <i className="ion-social-apple"></i> App Store</a>
                                <a className="button button-icon iq-mr-15 re4-mt-20" href="# "> <i className="ion-social-android"></i> Google Play</a>
                                <a className="button button-icon re7-mt-30 re4-mt-20" href="# "> <i className="ion-social-windows"></i> Windows Store</a>
                            </div>
                        </div>
                    </div>
                </section>


                <ComponentPrix id="pricing" />


                <ComponentBlog id="blog" />


                <div className="iq-our-clients white-bg iq-ptb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <Carousel1 />
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}

            <div className="footer">

                {/* <section className="overview-block-ptb iq-newsletter iq-bg iq-bg-fixed iq-over-black-80" style={{ background: `url(images/banner/bg.jpg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title iq-mb-40">
                                    <h2 className="title iq-tw-6 iq-font-white">Subscribe Our Newsletter</h2>
                                    <div className="divider white"></div>
                                    <p className="iq-font-white">Subscribe to our newsletter and don’t miss new arrivals, the latest update and special offers from Appino!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <form className="form-inline">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="exampleInputName2" placeholder="Enter Your Email Here" />
                                    </div>
                                    <a className="button bt-white iq-ml-25" href="# ">subscribe</a>
                                </form>
                                <div className="newsletter-phone iq-mt-50 wow fadeInUp" data-wow-duration="1s">
                                    <img className="img-responsive center-block" src={BANNIERE3()} alt="#" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ComponentContact /> */}

                <FooterAcceuil />

            </div>

            <div id="back-to-top" style={{ display: 'block' }}>
                <a className="top" id="top" href="#top"> <i className="ion-ios-upload-outline"></i> </a>
            </div>

            
        </>
    );
}

export default Home