import React, { useEffect, useState } from 'react';
import { DEFAULT1, LOGO_FAYSAL_COMPANY, LOGO_FAYSAL_COMPANY2 } from '../../../components/icons';
import { Drawer, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    MenuOutlined,
} from '@ant-design/icons';

const PageHeader = () => {
    const navigate = useNavigate();
    const allName = sessionStorage.getItem('sutielzatiwo');
    let afficheNom = "";

    if (allName !== null) {
        afficheNom = allName.split(" ")[0];
    }

    const isMobileScreen = window.innerWidth <= 768;
    const logo = {
        width: '100px',
        height: '60px',
        marginRight: '10px',
    }
    const companyName = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }



    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState('/');

    useEffect(() => {
        const pathName = location.pathname;
        setSelectedKeys(pathName);
    }, [location.pathname]);

    return (
        <>
            <div>

                {
                    isMobileScreen ? ('') : (
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '200px',
                            height: '100%',
                            backgroundColor: 'white',
                            paddingTop: '10px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                            zIndex: 1
                        }}>
                            <nav>
                                <ul style={{ listStyleType: 'none', paddingTop: "50%", paddingLeft: 10 }}>
                                    <Menu
                                        onSelect={() => onClose()}
                                        onClick={(item) => {
                                            navigate(item.key);
                                        }}
                                        selectedKeys={[selectedKeys]}
                                        mode='inline'
                                        items={[
                                            {
                                                label: 'DASHBOARD',
                                                key: '/admin-page',
                                                // icon: <AppstoreOutlined style={{ fontSize: 30 }} />,

                                            },
                                            {
                                                label: 'REACHARGE',
                                                key: '/transaction',
                                                // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                            },
                                            {
                                                label: 'PAYEMENT',
                                                key: '/payemnet',
                                                // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                            },
                                            {
                                                label: 'PAYEMENT RECU',
                                                key: '/payement-recu',
                                                // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                            },
                                            {
                                                label: 'PROFIL',
                                                key: '/profil',
                                                // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                            },


                                        ]}
                                    ></Menu>

                                </ul>

                            </nav>
                            <div onClick={() => navigate('/Login', { replace: true })} style={{
                                backgroundColor: '#D03A38',
                                color: 'white',
                                width: 180,
                                height: 40,
                                textAlign: 'center',
                                borderRadius: 10,
                                paddingTop: 10,
                                marginLeft: 15,
                                position: 'absolute',
                                bottom: 5
                            }} ><span style={{ fontSize: 16 }}>DECONNEXION</span></div>
                        </div>

                    )
                }

                <div style={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    backgroundColor: '#D03A38',
                    color: 'white',
                    padding: isMobileScreen ? '10px 0px' : '10px 40px',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    zIndex: 1,


                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={LOGO_FAYSAL_COMPANY()} alt="Company Logo" style={logo} />

                        </div>
                        <div style={{ display: 'flex' }}>
                            {
                                isMobileScreen ? ('') : (
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                        <img src={DEFAULT1()} style={{
                                            width: '45px',
                                            height: '45px',
                                            marginRight: '10px',
                                        }} />
                                        <div>
                                            <small>{afficheNom}</small>
                                            <style>Client</style>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                isMobileScreen ? (
                                    <MenuOutlined
                                        onClick={showDrawer}
                                        style={{
                                            fontSize: 30,
                                            color: 'white',
                                            marginRight: 28,
                                            marginLeft: 20,
                                            cursor: 'pointer',
                                        }}
                                    />
                                ) : (
                                    ''
                                )
                            }

                        </div>

                    </div>
                </div>
            </div>

            <Drawer
                title={<img src={LOGO_FAYSAL_COMPANY2()} alt='' height='40' />}
                placement={'left'}
                width={300}
                closeIcon={<MenuOutlined style={{ fontSize: 25, color: '#D03A38' }} />}
                open={open}
                onClose={onClose}
                zIndex={1100}
            >
                <div className='deznav' style={{ marginLeft: 0 }}>
                    <div className='deznav-scroll'>

                        <Sider collapsedWidth={0}>
                            <Menu
                                onSelect={() => onClose()}
                                onClick={(item) => {
                                    navigate(item.key);
                                }}
                                style={{ width: 280 }}
                                selectedKeys={[selectedKeys]}
                                mode='inline'
                                items={[
                                    {
                                        label: 'DASHBOARD',
                                        key: '/admin-page',
                                        // icon: <AppstoreOutlined style={{ fontSize: 30 }} />,

                                    },
                                    {
                                        label: 'REACHARGE',
                                        key: '/transaction',
                                        // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                    },
                                    {
                                        label: 'PAYEMENT',
                                        key: '/payemnet',
                                        // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                    },
                                    {
                                        label: 'PAYEMENT RECU',
                                        key: '/payement-recu',
                                        // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                    },
                                    {
                                        label: 'PROFIL',
                                        key: '/profil',
                                        // icon: <UserOutlined style={{ fontSize: 25 }} />,
                                    },


                                ]}
                            ></Menu>
                            <div onClick={() => navigate('/Login', { replace: true })} style={{
                                backgroundColor: '#D03A38',
                                color: 'white',
                                width: 250,
                                height: 40,
                                textAlign: 'center',
                                borderRadius: 10,
                                paddingTop: 10,
                                marginLeft: 15,
                                position: 'absolute',
                                bottom: 20
                            }} ><span style={{ fontSize: 16 }}>DECONNEXION</span></div>
                        </Sider>
                    </div>
                </div>
            </Drawer>
        </>

    );
};

export default PageHeader;