import React from "react";
import { LOGO_FAYSAL_COMPANY, LOGO_FAYSAL_COMPANY2 } from "../../components/icons";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <header style={{backgroundColor: '#CB2522', opacity:0.9}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav className="navbar navbar-default">
                                <div className="navbar-header">
                                    
                                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <Link className="navbar-brand" to="/dashboard">
                                        <img src={LOGO_FAYSAL_COMPANY()} alt="logo" />
                                    </Link>
                                </div>
                                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                    <ul className="nav navbar-nav navbar-right" id="top-menu">
                                        <li className="active"><Link to="/dashboard">DashBoard</Link></li>
                                        <li><Link to="/history">Historique</Link></li>
                                        <li><Link to="/params">Paramettres</Link></li>                                
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header