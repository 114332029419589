import React, { useState } from "react";
import { BG, DASH_LOGIN, LOGO_FAYSAL_COMPANY } from "../../components/icons";
import { Link, useNavigate } from "react-router-dom";
import FooterAcceuil from "../menus/FooterAcceuil";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../config/index'
import { Alert, Space, Spin } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const ValidationOtp = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [codeVal, setCodeVal] = useState("");

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");

    const ValiderOtp = async (event: any) => {
        event.preventDefault();
        let data = JSON.stringify({
            "code_val": codeVal,
            "phone": sessionStorage.getItem('okhsnuemroie')
        });
        setLoading(true);

        if (codeVal === '') {
            setShowAlertDanger(true)
            setMessage('The Email and Password fields must not be empty');
        } else {
            api.getHttpRequestNotToken(`/user/verify`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status === 200) {
                    sessionStorage.setItem('otokkhsnuemroiepersol', json.data.personal_token);
                    navigate('/default-password')
                }
                else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                setShowAlertDanger(true)
                setMessage(api.getMessageError(error))
                setCodeVal('')
            })
        }
        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
        }, 10000);
    }

    return (
        <>

            {
                isMatch ? (

                    <>
                        <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title iq-breadcrumb-title iq-mtb-1" >
                                            <h1 className="title iq-tw-8 iq-font-white" style={{ marginTop: 0 }}>Validation Otp</h1>
                                            <div className="divider white"></div>
                                        </div>
                                        <ul className="breadcrumb">
                                            <li><Link to="/home"><i className="ion-home"></i> Acceuil</Link></li>
                                            <li className="active" ><Link to="/login" style={{ color: "#D03A38" }}>Se connecter</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="main-content">

                            <section id="contact-us" className="iq-our-touch" style={{ marginTop: 40 }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="iq-get-in iq-pall-40 white-bg">

                                                <div className="row">

                                                    <div className="form-horizontal" id="contactform">

                                                        <div className="contact-form">
                                                            <div className="col-sm-2"></div>

                                                            <div className="col-sm-8">
                                                                <div className=" p-2 form-label rounded-sm text-center" style={{
                                                                    backgroundColor: 'white', marginBottom: 5,
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    fontSize: 16
                                                                }}>
                                                                    <small className="font-24 text-center ">
                                                                        Veuillez saisir le code de validation qui vous a été envoyée par e-mail ou par numéro de téléphone
                                                                    </small>
                                                                </div>
                                                                {
                                                                    message && <Space direction="vertical" style={{ width: '100%', marginBottom: 5 }}>
                                                                        {
                                                                            showAlertDanger &&
                                                                            <Alert type="error" message={message} showIcon closable />
                                                                        }
                                                                    </Space>
                                                                }

                                                                <div className="section-field">
                                                                    <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="text" placeholder="Code Otp 08394 *" name="name"
                                                                        value={codeVal}
                                                                        onChange={(e) => setCodeVal(e.target.value)}
                                                                    />
                                                                </div>
                                                                {!Loading && (
                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                        onClick={ValiderOtp}
                                                                    >VALIDER OTP</button>
                                                                )}

                                                                {Loading && (

                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                    >
                                                                        <Spin indicator={<AiOutlineLoading3Quarters style={{ fontSize: 24, color: 'white' }} />} />
                                                                    </button>
                                                                )}
                                                            </div>

                                                            <div className="col-sm-2"></div>
                                                        </div>
                                                    </div>
                                                    <div id="ajaxloader" style={{ display: 'none' }}><img className="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <FooterAcceuil />

                        </div>
                    </>
                ) : (
                    <>
                        <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title iq-breadcrumb-title iq-mtb-0">
                                            <h1 className="title iq-tw-8 iq-font-white">Validation Otp</h1>
                                            <div className="divider white" ></div>
                                        </div>
                                        <ul className="breadcrumb" style={{ marginTop: 1 }}>
                                            <li><Link to="/dashboard"><i className="ion-home"></i> Acceuil</Link></li>
                                            <li className="active" ><Link to="/login" style={{ color: "#D03A38" }}>Se connecter</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <div className="main-content pt-10" style={{ marginTop: 140 }}>

                            <section id="contact-us" className="iq-our-touch">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="iq-get-in iq-pall-40 white-bg">

                                                <div className="row">
                                                    <div id="formmessage">Success/Error Message Goes Here</div>
                                                    <div className="form-horizontal" id="contactform" >
                                                        <div className="contact-form">
                                                            <div className="col-sm-2"></div>

                                                            <div className="col-sm-8">
                                                                <div className=" p-2 form-label rounded-sm text-center" style={{
                                                                    backgroundColor: 'white', marginBottom: 5,
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    fontSize: 16
                                                                }}>
                                                                    <small className="font-24 text-center ">
                                                                        Veuillez saisir le code de validation qui vous a été envoyée par e-mail ou par numéro de téléphone
                                                                    </small>
                                                                </div>

                                                                {
                                                                    message && <div className=' mb-1'>
                                                                        {/* <label style={{color:'white'}}>vouchers</label> */}
                                                                        <Space direction="vertical" style={{ width: '100%', }}>
                                                                            {
                                                                                showAlertDanger &&
                                                                                <Alert type="error" message={message} showIcon closable />
                                                                            }
                                                                        </Space>

                                                                    </div>
                                                                }
                                                                <div className="section-field">
                                                                    <input id="name" type="text" placeholder="Code Otp 08394 *" name="name"
                                                                        value={codeVal}
                                                                        onChange={(e) => setCodeVal(e.target.value)}
                                                                    />
                                                                </div>

                                                                {!Loading && (
                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                        onClick={ValiderOtp}
                                                                    >VALIDER OTP</button>
                                                                )}

                                                                {Loading && (

                                                                    <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                                                    >
                                                                        <Spin indicator={<AiOutlineLoading3Quarters style={{ fontSize: 24 }} />} />
                                                                    </button>
                                                                )}

                                                            </div>

                                                            <div className="col-sm-2"></div>
                                                        </div>
                                                    </div><br />
                                                    <div>
                                                        <div style={{ display: 'none' }}><img className="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="" /></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <FooterAcceuil />

                        </div>
                    </>
                )
            }


        </>
    )
}

export default ValidationOtp