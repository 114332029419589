import React from 'react';

import { HashRouter, Routes, Route } from 'react-router-dom';
import Login from './views/authent/Login';
import LogUp from './views/authent/LogUp';
import DefaultLayout from './layout/DefaultLayout';
import Home from './views/Home';
import Acount from './views/Acount';

import './assets/assets1/css/bootstrap.min.css'
import './assets/assets1/css/owl-carousel/owl.carousel.css'
import './assets/assets1/css/font-awesome.css';
import './assets/assets1/css/magnific-popup/magnific-popup.css';
import './assets/assets1/css/animate.css';
import './assets/assets1/css/ionicons.min.css';
import './assets/assets1/css/style.css';
import './assets/assets1/css/responsive.css';
import './assets/assets1/css/style-customizer.css';
import './assets/assets1/css/custom.css'
import Apropos from './views/mobile/Apropos';
import Future from './views/mobile/Future';
import Screenshots from './views/mobile/Screenshots';
import Team from './views/mobile/Team';
import Tarif from './views/mobile/Tarif';
import Blog from './views/mobile/Blog';
import Contact from './views/mobile/Contact';
import ValidationOtp from './views/authent/validationOtp';
import DefaulPassWord from './views/authent/PasswordDefault';
import AdminHomePage from './views/nouveaudesign/adminHome';
import TransactionPage from './views/transaction/Transaction';
import ProfilPage from './views/profil/PageProfil';
import PaeimentPage from './views/paiement/PagePaiement';
import PaeimentRecuPage from './views/paiementrecu/PaiementRecu';

class App extends React.Component {
   render() {
      return (
          <HashRouter>
              <Routes>
                       <Route path='/' element={<Home />} />
                       <Route path='/home' element={<Home />} />
                       <Route path='/home#future' element={<Home />} />
                       <Route path='/login' element={<Login />} />
                       <Route path='/logup' element={<LogUp/>} />
                       <Route path='/account' element={<Acount/>} />
                       <Route path='/validate-otp' element={<ValidationOtp/>} />
                       <Route path='/default-password' element={<DefaulPassWord/>} />
                       <Route path='/apropos' element={<Apropos/>} />
                       <Route path='/future' element={<Future/>} />
                       <Route path='/screen' element={<Screenshots/>} />
                       <Route path='/team' element={<Team/>} />
                       <Route path='/tarif' element={<Tarif/>} />
                       <Route path='/blog' element={<Blog/>} />
                       <Route path='/contact' element={<Contact/>} />
                       <Route path='/admin-page' element={<AdminHomePage/>}/>
                       <Route path='/transaction' element={<TransactionPage/>}/>
                       <Route path='/payemnet' element={<PaeimentPage/>}/>
                       <Route path='/payement-recu' element={<PaeimentRecuPage/>}/>
                       <Route path='/profil' element={<ProfilPage/>}/>
                       {/* <Route path='/dashboard' element={<Admin/>} /> */}
                       <Route path='*' element={<DefaultLayout />} />
                  </Routes>
          </HashRouter>
      );
   }
}

export default App;
