import { Alert, Select, Space, Spin } from "antd";
import PageHeader from "../nouveaudesign/adminHeder/pageHeader";
import PhoneInput from "react-phone-input-2";
import { useEffect, useRef, useState } from "react";
import { CARD, DEFAULT1 } from "../../components/icons";
import { CountryDropdown } from 'react-country-region-selector';
import CountryList from 'react-select-country-list';
import api from '../../config/index'
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const { Option } = Select;


const ProfilPage = () => {
    const isMobileScreen = window.innerWidth <= 768;
    const navigate = useNavigate()
    const styles = {

        userInfo: {
            fontSize: '1.2em',
            fontWeight: 'bold',
            marginBottom: isMobileScreen ? '1px' : '10px',
            marginLeft: isMobileScreen ? '10px' : '5px'
        },
        navigationBar: {
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
        },
        summaryBox: {
            border: '1px solid #ccc',
            padding: '10px',
            marginBottom: '10px',
        },
        greenBox: {
            color: 'green',
        },
        redBox: {
            color: 'red',
        },
        revenueAmount: {
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
        dataWidget: {
            backgroundColor: '#fff',
            padding: '10px',
            marginBottom: '10px',
        },
        chart: {
            backgroundColor: '#f9f9f9',
            padding: '10px',
            marginBottom: '10px',
        },
        inputSearch: {
            padding: '5px',
            marginBottom: '10px',
        },
        selectCompany: {
            padding: '5px',
        },
    };
    const [Loading, setLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');

    const [phone, setPhone] = useState('');

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");
    const [fileblog, setFileblog] = useState(null)
    const [_avatarUser, setAvatarUser] = useState<File | null>(null);
    const [selectedImageBack, setSelectedImageBack] = useState<string | null>(
        null
    );
    const [identityCardBack, setIdentityCardBack] = useState<File>();

    const [selectedImage, setSelectedImage] = useState<string | null>(DEFAULT1());
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [fullname, setFullname] = useState('')
    const [gender, setGender] = useState('')
    const [email, setEmail] = useState('')
    const [avatar, setAvatar] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [dateBirth, setDateBirth] = useState('')
    const [placeBirth, setPlaceBirth] = useState('')
    const [cardId, setCardId] = useState('')

    useEffect(() => {
        if (sessionStorage.getItem("onktxonktxmmarger") === null) {
            navigate("/login")
        }
        LoadPrile()
    }, [])

    const LoadPrile = async () => {
        api.getHttpRequest(`/user/profil`, {}, 'GET').then((response) => {

            const json = response.data.data;

            const startDate = new Date(json.date_birth);
            const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            setDateBirth(formattedStartDate)
            setFullname(json.fullname);
            setEmail(json.email);
            setPhone(json.phone);
            setAvatar(json.avatar);
            // setPersonnelNumber(json.personnel_number)
            setPlaceBirth(json.place_birth)
            setGender(json.gender)
            setCountry(json.country)
            setCity(json.city)
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const NewUpdateProfile = () => {
        let data = JSON.stringify({
            "fullname": fullname,
            "phone": phone,
            "gender": gender,
            "email": email,
            "avatar": "file.png",
            "country": country,
            "city": city,
            "date_birth": dateBirth,
            "place_birth": placeBirth,
            "card_id": "file.png"
        });

        setLoading(true);

        if (fullname === '' || phone === '') {
            setShowAlertDanger(true)
            setMessage('Veillez selectionner tous les chamos');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true)
            setMessage('Le numero enter est incorect');
        }
        else {
            api.getHttpRequest(`/user/update-profil`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSuccess(true)
                    setMessage(json.message);
                    LoadPrile()
                } else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                setShowAlertDanger(true)
                setMessage(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertSuccess(false)
            setShowAlertDanger(false)
        }, 3000);
    }


    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            if (file.size > 1024 * 1024) {
                setShowAlertDanger(true)
                setMessage('Please select a file that is less than 1 MB.');
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result as string);
                    setAvatarUser(file);
                };

                reader.readAsDataURL(file);
            }
        }
    };


    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const counrtyOption = [
        { "name": "Afghanistan", "code": "AF" },
        { "name": "land Islands", "code": "AX" },
        { "name": "Albania", "code": "AL" },
        { "name": "Algeria", "code": "DZ" },
        { "name": "American Samoa", "code": "AS" },
        { "name": "AndorrA", "code": "AD" },
        { "name": "Angola", "code": "AO" },
        { "name": "Anguilla", "code": "AI" },
        { "name": "Antarctica", "code": "AQ" },
        { "name": "Antigua and Barbuda", "code": "AG" },
        { "name": "Argentina", "code": "AR" },
        { "name": "Armenia", "code": "AM" },
        { "name": "Aruba", "code": "AW" },
        { "name": "Australia", "code": "AU" },
        { "name": "Austria", "code": "AT" },
        { "name": "Azerbaijan", "code": "AZ" },
        { "name": "Bahamas", "code": "BS" },
        { "name": "Bahrain", "code": "BH" },
        { "name": "Bangladesh", "code": "BD" },
        { "name": "Barbados", "code": "BB" },
        { "name": "Belarus", "code": "BY" },
        { "name": "Belgium", "code": "BE" },
        { "name": "Belize", "code": "BZ" },
        { "name": "Benin", "code": "BJ" },
        { "name": "Bermuda", "code": "BM" },
        { "name": "Bhutan", "code": "BT" },
        { "name": "Bolivia", "code": "BO" },
        { "name": "Bosnia and Herzegovina", "code": "BA" },
        { "name": "Botswana", "code": "BW" },
        { "name": "Bouvet Island", "code": "BV" },
        { "name": "Brazil", "code": "BR" },
        { "name": "British Indian Ocean Territory", "code": "IO" },
        { "name": "Brunei Darussalam", "code": "BN" },
        { "name": "Bulgaria", "code": "BG" },
        { "name": "Burkina Faso", "code": "BF" },
        { "name": "Burundi", "code": "BI" },
        { "name": "Cambodia", "code": "KH" },
        { "name": "Cameroon", "code": "CM" },
        { "name": "Canada", "code": "CA" },
        { "name": "Cape Verde", "code": "CV" },
        { "name": "Cayman Islands", "code": "KY" },
        { "name": "Central African Republic", "code": "CF" },
        { "name": "Chad", "code": "TD" },
        { "name": "Chile", "code": "CL" },
        { "name": "China", "code": "CN" },
        { "name": "Christmas Island", "code": "CX" },
        { "name": "Cocos (Keeling) Islands", "code": "CC" },
        { "name": "Colombia", "code": "CO" },
        { "name": "Comoros", "code": "KM" },
        { "name": "Congo", "code": "CG" },
        { "name": "Congo, The Democratic Republic of the", "code": "CD" },
        { "name": "Cook Islands", "code": "CK" },
        { "name": "Costa Rica", "code": "CR" },
        { "name": "Cote D\"Ivoire", "code": "CI" },
        { "name": "Croatia", "code": "HR" },
        { "name": "Cuba", "code": "CU" },
        { "name": "Cyprus", "code": "CY" },
        { "name": "Czech Republic", "code": "CZ" },
        { "name": "Denmark", "code": "DK" },
        { "name": "Djibouti", "code": "DJ" },
        { "name": "Dominica", "code": "DM" },
        { "name": "Dominican Republic", "code": "DO" },
        { "name": "Ecuador", "code": "EC" },
        { "name": "Egypt", "code": "EG" },
        { "name": "El Salvador", "code": "SV" },
        { "name": "Equatorial Guinea", "code": "GQ" },
        { "name": "Eritrea", "code": "ER" },
        { "name": "Estonia", "code": "EE" },
        { "name": "Ethiopia", "code": "ET" },
        { "name": "Falkland Islands (Malvinas)", "code": "FK" },
        { "name": "Faroe Islands", "code": "FO" },
        { "name": "Fiji", "code": "FJ" },
        { "name": "Finland", "code": "FI" },
        { "name": "France", "code": "FR" },
        { "name": "French Guiana", "code": "GF" },
        { "name": "French Polynesia", "code": "PF" },
        { "name": "French Southern Territories", "code": "TF" },
        { "name": "Gabon", "code": "GA" },
        { "name": "Gambia", "code": "GM" },
        { "name": "Georgia", "code": "GE" },
        { "name": "Germany", "code": "DE" },
        { "name": "Ghana", "code": "GH" },
        { "name": "Gibraltar", "code": "GI" },
        { "name": "Greece", "code": "GR" },
        { "name": "Greenland", "code": "GL" },
        { "name": "Grenada", "code": "GD" },
        { "name": "Guadeloupe", "code": "GP" },
        { "name": "Guam", "code": "GU" },
        { "name": "Guatemala", "code": "GT" },
        { "name": "Guernsey", "code": "GG" },
        { "name": "Guinea", "code": "GN" },
        { "name": "Guinea-Bissau", "code": "GW" },
        { "name": "Guyana", "code": "GY" },
        { "name": "Haiti", "code": "HT" },
        { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
        { "name": "Holy See (Vatican City State)", "code": "VA" },
        { "name": "Honduras", "code": "HN" },
        { "name": "Hong Kong", "code": "HK" },
        { "name": "Hungary", "code": "HU" },
        { "name": "Iceland", "code": "IS" },
        { "name": "India", "code": "IN" },
        { "name": "Indonesia", "code": "ID" },
        { "name": "Iran, Islamic Republic Of", "code": "IR" },
        { "name": "Iraq", "code": "IQ" },
        { "name": "Ireland", "code": "IE" },
        { "name": "Isle of Man", "code": "IM" },
        { "name": "Israel", "code": "IL" },
        { "name": "Italy", "code": "IT" },
        { "name": "Jamaica", "code": "JM" },
        { "name": "Japan", "code": "JP" },
        { "name": "Jersey", "code": "JE" },
        { "name": "Jordan", "code": "JO" },
        { "name": "Kazakhstan", "code": "KZ" },
        { "name": "Kenya", "code": "KE" },
        { "name": "Kiribati", "code": "KI" },
        { "name": "Korea, Democratic People\"S Republic of", "code": "KP" },
        { "name": "Korea, Republic of", "code": "KR" },
        { "name": "Kuwait", "code": "KW" },
        { "name": "Kyrgyzstan", "code": "KG" },
        { "name": "Lao People\"S Democratic Republic", "code": "LA" },
        { "name": "Latvia", "code": "LV" },
        { "name": "Lebanon", "code": "LB" },
        { "name": "Lesotho", "code": "LS" },
        { "name": "Liberia", "code": "LR" },
        { "name": "Libyan Arab Jamahiriya", "code": "LY" },
        { "name": "Liechtenstein", "code": "LI" },
        { "name": "Lithuania", "code": "LT" },
        { "name": "Luxembourg", "code": "LU" },
        { "name": "Macao", "code": "MO" },
        { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
        { "name": "Madagascar", "code": "MG" },
        { "name": "Malawi", "code": "MW" },
        { "name": "Malaysia", "code": "MY" },
        { "name": "Maldives", "code": "MV" },
        { "name": "Mali", "code": "ML" },
        { "name": "Malta", "code": "MT" },
        { "name": "Marshall Islands", "code": "MH" },
        { "name": "Martinique", "code": "MQ" },
        { "name": "Mauritania", "code": "MR" },
        { "name": "Mauritius", "code": "MU" },
        { "name": "Mayotte", "code": "YT" },
        { "name": "Mexico", "code": "MX" },
        { "name": "Micronesia, Federated States of", "code": "FM" },
        { "name": "Moldova, Republic of", "code": "MD" },
        { "name": "Monaco", "code": "MC" },
        { "name": "Mongolia", "code": "MN" },
        { "name": "Montenegro", "code": "ME" },
        { "name": "Montserrat", "code": "MS" },
        { "name": "Morocco", "code": "MA" },
        { "name": "Mozambique", "code": "MZ" },
        { "name": "Myanmar", "code": "MM" },
        { "name": "Namibia", "code": "NA" },
        { "name": "Nauru", "code": "NR" },
        { "name": "Nepal", "code": "NP" },
        { "name": "Netherlands", "code": "NL" },
        { "name": "Netherlands Antilles", "code": "AN" },
        { "name": "New Caledonia", "code": "NC" },
        { "name": "New Zealand", "code": "NZ" },
        { "name": "Nicaragua", "code": "NI" },
        { "name": "Niger", "code": "NE" },
        { "name": "Nigeria", "code": "NG" },
        { "name": "Niue", "code": "NU" },
        { "name": "Norfolk Island", "code": "NF" },
        { "name": "Northern Mariana Islands", "code": "MP" },
        { "name": "Norway", "code": "NO" },
        { "name": "Oman", "code": "OM" },
        { "name": "Pakistan", "code": "PK" },
        { "name": "Palau", "code": "PW" },
        { "name": "Palestinian Territory, Occupied", "code": "PS" },
        { "name": "Panama", "code": "PA" },
        { "name": "Papua New Guinea", "code": "PG" },
        { "name": "Paraguay", "code": "PY" },
        { "name": "Peru", "code": "PE" },
        { "name": "Philippines", "code": "PH" },
        { "name": "Pitcairn", "code": "PN" },
        { "name": "Poland", "code": "PL" },
        { "name": "Portugal", "code": "PT" },
        { "name": "Puerto Rico", "code": "PR" },
        { "name": "Qatar", "code": "QA" },
        { "name": "Reunion", "code": "RE" },
        { "name": "Romania", "code": "RO" },
        { "name": "Russian Federation", "code": "RU" },
        { "name": "RWANDA", "code": "RW" },
        { "name": "Saint Helena", "code": "SH" },
        { "name": "Saint Kitts and Nevis", "code": "KN" },
        { "name": "Saint Lucia", "code": "LC" },
        { "name": "Saint Pierre and Miquelon", "code": "PM" },
        { "name": "Saint Vincent and the Grenadines", "code": "VC" },
        { "name": "Samoa", "code": "WS" },
        { "name": "San Marino", "code": "SM" },
        { "name": "Sao Tome and Principe", "code": "ST" },
        { "name": "Saudi Arabia", "code": "SA" },
        { "name": "Senegal", "code": "SN" },
        { "name": "Serbia", "code": "RS" },
        { "name": "Seychelles", "code": "SC" },
        { "name": "Sierra Leone", "code": "SL" },
        { "name": "Singapore", "code": "SG" },
        { "name": "Slovakia", "code": "SK" },
        { "name": "Slovenia", "code": "SI" },
        { "name": "Solomon Islands", "code": "SB" },
        { "name": "Somalia", "code": "SO" },
        { "name": "South Africa", "code": "ZA" },
        { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
        { "name": "Spain", "code": "ES" },
        { "name": "Sri Lanka", "code": "LK" },
        { "name": "Sudan", "code": "SD" },
        { "name": "Suriname", "code": "SR" },
        { "name": "Svalbard and Jan Mayen", "code": "SJ" },
        { "name": "Swaziland", "code": "SZ" },
        { "name": "Sweden", "code": "SE" },
        { "name": "Switzerland", "code": "CH" },
        { "name": "Syrian Arab Republic", "code": "SY" },
        { "name": "Taiwan, Province of China", "code": "TW" },
        { "name": "Tajikistan", "code": "TJ" },
        { "name": "Tanzania, United Republic of", "code": "TZ" },
        { "name": "Thailand", "code": "TH" },
        { "name": "Timor-Leste", "code": "TL" },
        { "name": "Togo", "code": "TG" },
        { "name": "Tokelau", "code": "TK" },
        { "name": "Tonga", "code": "TO" },
        { "name": "Trinidad and Tobago", "code": "TT" },
        { "name": "Tunisia", "code": "TN" },
        { "name": "Turkey", "code": "TR" },
        { "name": "Turkmenistan", "code": "TM" },
        { "name": "Turks and Caicos Islands", "code": "TC" },
        { "name": "Tuvalu", "code": "TV" },
        { "name": "Uganda", "code": "UG" },
        { "name": "Ukraine", "code": "UA" },
        { "name": "United Arab Emirates", "code": "AE" },
        { "name": "United Kingdom", "code": "GB" },
        { "name": "United States", "code": "US" },
        { "name": "United States Minor Outlying Islands", "code": "UM" },
        { "name": "Uruguay", "code": "UY" },
        { "name": "Uzbekistan", "code": "UZ" },
        { "name": "Vanuatu", "code": "VU" },
        { "name": "Venezuela", "code": "VE" },
        { "name": "Viet Nam", "code": "VN" },
        { "name": "Virgin Islands, British", "code": "VG" },
        { "name": "Virgin Islands, U.S.", "code": "VI" },
        { "name": "Wallis and Futuna", "code": "WF" },
        { "name": "Western Sahara", "code": "EH" },
        { "name": "Yemen", "code": "YE" },
        { "name": "Zambia", "code": "ZM" },
        { "name": "Zimbabwe", "code": "ZW" }
    ]

    const handleFileBackChange = (e: any) => {
        const file = e.target.files[0];

        setIdentityCardBack(file);
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImageBack(imageUrl);
        } else {
            setSelectedImageBack(null);
        }
    };

    return (
        <>
            <PageHeader />
            <div style={{
                fontFamily: 'Arial, sans-serif',
                backgroundColor: 'white',
                paddingLeft: isMobileScreen ? '5px' : '16%',
                paddingTop: isMobileScreen ? '30%' : '8%',
                paddingRight: '2%',
            }}>
                <div style={styles.userInfo}>
                    <h4 className="fs-20 text-black mb-2">Profile
                    </h4>
                </div>


                <div className="col-md-10 col-md-offset-1" style={{ paddingTop: isMobileScreen ? '0px' : '12%', }}>
                    <div className="iq-get-in iq-pall-40 white-bg">

                        <div className="row">

                            <div className="form-horizontal" id="contactform">
                                <div className="contact-form">
                                    <div className="col-sm-2"></div>

                                    <div className="col-sm-8">
                                        {
                                            message && <Space direction="vertical" style={{ width: '100%', marginBottom: 5 }}>
                                                {
                                                    showAlertDanger &&
                                                    <Alert type="error" message={message} showIcon closable />
                                                }
                                            </Space>
                                        }
                                        <div className="row">

                                            <div className='col-md-12 row'>

                                                <div className='col-md-2 '>
                                                    <div className="pic-holder">
                                                        <img
                                                            style={{ width: 150, height: 150, borderRadius: '100%', marginLeft: isMobileScreen?100:180 }}
                                                            id="profilePic"
                                                            className="pic"
                                                            src={selectedImage || DEFAULT1()}
                                                            onClick={handleImageClick}
                                                        />
                                                        <input
                                                            className="uploadProfileInput"
                                                            type="file"
                                                            name="profile_pic"
                                                            id="newProfilePhoto"
                                                            accept="image/*"
                                                            style={{ opacity: 0 }}
                                                            ref={fileInputRef}
                                                            onChange={handleImageChange}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="text" placeholder="Nom Complet*" name="name"
                                                value={fullname}
                                                onChange={(e) => setFullname(e.target.value)}
                                            />
                                        </div>
                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <select style={{
                                                color: 'black',
                                                fontSize: 'bold',
                                                borderRadius: 50,
                                                height: 45,
                                                border: '2px solid #F5F5F5',
                                                background: 'white' // Définir l'arrière-plan sur blanc
                                            }} id="gender"
                                                name='gender' className="section-field"
                                                value={gender}
                                                onChange={(e) => setGender(e.target.value)}
                                            >
                                                <option>Genre</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>

                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <PhoneInput
                                                inputStyle={{
                                                    width: '100%',
                                                    paddingLeft: '50px'
                                                } as React.CSSProperties}
                                                country={'cd'}
                                                value={phone}
                                                onChange={changePhoneNumber}
                                                enableSearch={true}
                                                countryCodeEditable={true}
                                            />
                                        </div>

                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="mail" placeholder="Email " name="name"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <Select showSearch dropdownStyle={{ height: 200 }}
                                                className="section-field custom-country-dropdown "
                                                placeholder="Select District" optionFilterProp="children"
                                                value={country}
                                                onChange={(value) => {
                                                    setCountry(value);
                                                }}
                                                filterOption={(input: string, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                <Option selected value="">Pays
                                                </Option>
                                                {
                                                    counrtyOption &&
                                                        counrtyOption !== undefined ?
                                                        counrtyOption.map((item, i) => (
                                                            <Option key={i} value={item.name}>{item.name}</Option>
                                                        ))
                                                        : ""
                                                }
                                            </Select>
                                        </div>

                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="text" placeholder="Ville " name="name"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </div>
                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="date" placeholder="Date " name="name"
                                                value={dateBirth}
                                                onChange={(e) => setDateBirth(e.target.value)}
                                            />
                                        </div>

                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="text" placeholder="Lieu de naissance " name="name"

                                            />
                                        </div>


                                        <div className="section-field" style={{ marginBottom: 18 }}>
                                            <input style={{ color: 'black', fontSize: 'bold' }} id="name" type="file" placeholder="Ville " name="name"
                                                onChange={handleFileBackChange}
                                            />
                                        </div>


                                        <div>
                                            {selectedImageBack ? (
                                                <img
                                                    className='img-fluid'
                                                    src={selectedImageBack}
                                                    style={{ width: 350,height:250}}
                                                />
                                            ) : (
                                                ''
                                            )}
                                            {/* <img
                                                    className='img-fluid'
                                                    src={CARD()}
                                                    style={{ width: 350, height:250}}
                                                /> */}
                                        </div>

                                        <div>
                                        {
                                            !Loading &&
                                            <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4 mt-10"
                                                onClick={NewUpdateProfile}
                                            >UPDATE</button>
                                        }

                                        {Loading && (

                                            <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-4"
                                            >
                                                <Spin indicator={<AiOutlineLoading3Quarters style={{ fontSize: 24, color: 'white' }} />} />
                                            </button>
                                        )}
                                        </div>

                                        
                                    </div>

                                    <div className="col-sm-2"></div>
                                </div>
                            </div>
                            <div id="ajaxloader" style={{ display: 'none' }}><img className="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilPage