import { BG } from "../../components/icons"
import ComponentContact from "../../controllers/ComponentContact"
import FooterAcceuil from "../menus/FooterAcceuil"
import HeaderHorizontal from "../menus/HeaderHorizontal"

const Contact = () => {
    return (
        <>
          <HeaderHorizontal/>
            <section className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow" style={{ backgroundImage: `url(${BG()})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title iq-breadcrumb-title iq-mtb-0" style={{paddingTop:35}}>
                                <h1 className="title iq-tw-8 iq-font-white" >Nous Contactez</h1>
                                <div className="divider white" ></div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>

            <ComponentContact/>


            <FooterAcceuil/>
        </>
    )
}

export default Contact