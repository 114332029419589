import { IMG_TEAM_1, IMG_TEAM_2, IMG_TEAM_3, IMG_TEAM_4 } from "../components/icons"

const ComponentTeam = (props:any) => {
   return (
    <>
       <section id={props.id} className="overview-block-ptb white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-title">
                                    <h2 className="title iq-tw-6">Notre Equipe</h2>
                                    <div className="divider"></div>
                                    <p>Meet the faces behind our company. Our team offers the most up-to-date, sustainable custom solutions to the problem. They work so hard to provide best to their customers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 col-md-3">
                                <div className="iq-team">
                                    <img src={IMG_TEAM_1()} className="img-responsive center-block" alt="#" />
                                    <div className="iq-team-info text-center">
                                        <h4 className="iq-font-white iq-tw-5">Jason Adams</h4>
                                        <span className="team-post iq-tw-5">UI/UX Designer</span>
                                        <div className="share iq-mt-15">
                                            <span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"></i> Share With</span>
                                            <nav>
                                                <a href="#"><i className="fa fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-facebook"></i></a>
                                                <a href="#"><i className="fa fa-google"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 col-md-3">
                                <div className="iq-team re7-mt-50">
                                    <img src={IMG_TEAM_2()} className="img-responsive center-block" alt="#" />
                                    <div className="iq-team-info text-center">
                                        <h4 className="iq-font-white iq-tw-5">Amy Adams</h4>
                                        <span className="team-post iq-tw-5">UI/UX Designer</span>
                                        <div className="share iq-mt-15">
                                            <span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"></i> Share With</span>
                                            <nav>
                                                <a href="#"><i className="fa fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-facebook"></i></a>
                                                <a href="#"><i className="fa fa-google"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                                <div className="iq-team">
                                    <img src={IMG_TEAM_3()} className="img-responsive center-block" alt="#" />
                                    <div className="iq-team-info text-center">
                                        <h4 className="iq-font-white iq-tw-5">John Deo</h4>
                                        <span className="team-post iq-tw-5">UI/UX Designer</span>
                                        <div className="share iq-mt-15">
                                            <span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"></i> Share With</span>
                                            <nav>
                                                <a href="#"><i className="fa fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-facebook"></i></a>
                                                <a href="#"><i className="fa fa-google"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">
                                <div className="iq-team">
                                    <img src={IMG_TEAM_4()} className="img-responsive center-block" alt="#" />
                                    <div className="iq-team-info text-center">
                                        <h4 className="iq-font-white iq-tw-5">James Clayton</h4>
                                        <span className="team-post iq-tw-5">UI/UX Designer</span>
                                        <div className="share iq-mt-15">
                                            <span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"></i> Share With</span>
                                            <nav>
                                                <a href="#"><i className="fa fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-facebook"></i></a>
                                                <a href="#"><i className="fa fa-google"></i></a>
                                                <a href="#"><i className="fa fa-github"></i></a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    </>
   )
}

export default ComponentTeam