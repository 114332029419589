
const ComponentContact = () => {
    return (
        <>
            <section className="iq-our-info white-bg overview-block-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="iq-info-box text-center iq-pt-50">
                                <div className="info-icon green-bg"><i className="ion-ios-location-outline" aria-hidden="true"></i></div>
                                <h4 className="iq-tw-6 iq-mt-25 iq-mb-15">Address</h4>
                                <span className="lead iq-tw-6">1234 North Avenue Luke Lane, South Bend, IN 360001</span>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="iq-info-box text-center iq-pt-50">
                                <div className="info-icon green-bg"><i className="ion-ios-telephone-outline" aria-hidden="true"></i></div>
                                <h4 className="iq-tw-6 iq-mt-25 iq-mb-15">Phone</h4>
                                <span className="lead iq-tw-6">+0123 456 789</span>
                                <p>Mon-Fri 8:00am - 8:00pm</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="iq-info-box text-center iq-pt-50">
                                <div className="info-icon green-bg"><i className="ion-ios-email-outline" aria-hidden="true"></i></div>
                                <h4 className="iq-tw-6 iq-mt-25 iq-mb-15">Mail</h4>
                                <span className="lead iq-tw-6">support@appino.com</span>
                                <p>24 X 7 online support</p>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <ul className="info-share">
                                <li ><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li ><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li ><a href="#"><i className="fa fa-google"></i></a></li>
                                <li ><a href="#"><i className="fa fa-github"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div className="iq-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.1038282635864!2d29.214314910383624!3d-1.6797488982976139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dd0f77dd21b51f%3A0x581b3fa6f38a8eac!2sUn%20Jour%20Nouveau%20Center!5e0!3m2!1sfr!2scd!4v1682589905199!5m2!1sfr!2scd" style={{ border: 0 }} allowFullScreen></iframe>
            </div>


            <section id="contact" className="iq-our-touch">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="iq-get-in iq-pall-40 white-bg">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="heading-title iq-mb-60">
                                            <h2 className="title iq-tw-6">Get in Touch</h2>
                                            <div className="divider"></div>
                                            <p>Do you have a question for us? we'd love to here from you and we would be happy to answer your questions. Reach out to us and we'll respond as soon as we can.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="formmessage">Success/Error Message Goes Here</div>
                                    <form className="form-horizontal" id="contactform" method="post" action="https://templates.iqonic.design/appino/html/php/contact-form.php">
                                        <div className="contact-form">
                                            <div className="col-sm-6">
                                                <div className="section-field">
                                                    <input id="name" type="text" placeholder="Name*" name="name" />
                                                </div>
                                                <div className="section-field">
                                                    <input type="email" placeholder="Email*" name="email" />
                                                </div>
                                                <div className="section-field">
                                                    <input type="text" placeholder="Phone*" name="phone" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="section-field textarea">
                                                    <textarea className="input-message" placeholder="Comment*" name="message"></textarea>
                                                </div>
                                                <input type="hidden" name="action" value="sendEmail" />
                                                <button id="submit" name="submit" type="submit" value="Send" className="button pull-right iq-mt-40">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div id="ajaxloader" style={{ display: 'none' }}><img className="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComponentContact